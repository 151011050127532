import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { routes } from "../../routes";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import {
  DefaultReport,
  getEmptySelectedQueryOptions,
  Report,
  ReportSelectedQueryOptions,
  SavedReport,
} from "../../types/report";
import { ReportingRightSidebar } from "./ReportingRightSidebar";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { downloadBlobFile } from "../../utils/file";
import { ReportingContent } from "./ReportingContent";

const ReportingAddEditRoute: React.FC = () => {
  const navigate = useNavigate();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showErrorToast } = useGlobalToastNotificationContext();
  const [report, setReport] = useState<Report>();
  const { reportGuid } = useParams();
  const shouldDisplayDefaultReportRef = useRef(true);
  const sidebarWidth = 400;

  usePageMetadata({
    title: reportGuid
      ? `${routes.reportingEdit.label} ${report?.reportName ?? ""}`
      : routes.reportingAdd.label,
  });

  useEffect(() => {
    setBreadcrumbs([
      { label: routes.reporting.label, href: routes.reporting.path },
      { label: reportGuid ? "Edit" : "Add" },
    ]);
  }, [report?.reportName, reportGuid, setBreadcrumbs]);

  const {
    data: defaultReportData,
    request: defaultReportRequest,
    status: defaultReportStatus,
    loading: defaultReportLoading,
    errorMessage: defaultReportErrorMessage,
  } = useApiRequest<DefaultReport>(false);

  const { request: reportQueryRequest, errorMessage: reportQueryErrorMessage } =
    useApiRequest<Report>();

  const {
    data: reportData,
    request: reportRequest,
    status: reportStatus,
    loading: reportLoading,
    errorMessage: reportErrorMessage,
  } = useApiRequest<Report>();

  const {
    request: saveAsNewReportRequest,
    loading: saveAsNewReportLoading,
    errorMessage: saveAsNewReportErrorMessage,
  } = useApiRequest<SavedReport>();

  const {
    request: editReportRequest,
    loading: editReportLoading,
    errorMessage: editReportErrorMessage,
  } = useApiRequest<SavedReport>();

  const {
    data: downloadReportData,
    request: downloadReportRequest,
    loading: downloadReportLoading,
    response: downloadReportResponse,
    errorMessage: downloadReportErrorMessage,
  } = useApiRequest<Blob>();

  const { data: savedReportsData, request: savedReportsRequest } =
    useApiRequest<SavedReport[]>();

  const {
    request: deleteReportRequest,
    errorMessage: deleteReportErrorMessage,
  } = useApiRequest();

  const refreshSavedReports = useCallback(
    () => savedReportsRequest("/reports/saved", { method: "GET" }),
    [savedReportsRequest]
  );

  useEffect(() => {
    defaultReportRequest("/reports/default?qo=1", { method: "GET" });
  }, [defaultReportRequest]);

  useEffect(() => {
    if (reportGuid) {
      reportRequest(`/reports/saved/${reportGuid}`, { method: "GET" });
    }
  }, [reportGuid, reportRequest]);

  useEffect(() => {
    refreshSavedReports();
  }, [refreshSavedReports]);

  useEffect(() => {
    if (
      defaultReportData &&
      defaultReportStatus === "ok" &&
      defaultReportLoading === false &&
      !reportGuid &&
      shouldDisplayDefaultReportRef.current
    ) {
      setReport({ ...defaultReportData });
    }
  }, [
    defaultReportData,
    defaultReportLoading,
    defaultReportStatus,
    reportGuid,
  ]);

  useEffect(() => {
    if (defaultReportErrorMessage) {
      showErrorToast({ message: defaultReportErrorMessage });
    }
  }, [defaultReportErrorMessage, showErrorToast]);

  useEffect(() => {
    if (reportData && reportStatus === "ok" && reportLoading === false) {
      setReport({ ...reportData });
    }
  }, [reportData, reportLoading, reportStatus]);

  useEffect(() => {
    if (reportErrorMessage) {
      showErrorToast({ message: reportErrorMessage });
    }
  }, [reportErrorMessage, showErrorToast]);

  useEffect(() => {
    if (
      downloadReportData &&
      downloadReportResponse &&
      downloadReportLoading === false
    ) {
      downloadBlobFile(downloadReportData, downloadReportResponse);
    }
  }, [downloadReportData, downloadReportLoading, downloadReportResponse]);

  useEffect(() => {
    if (downloadReportErrorMessage) {
      showErrorToast({ message: downloadReportErrorMessage });
    }
  }, [downloadReportErrorMessage, showErrorToast]);

  useEffect(() => {
    if (reportQueryErrorMessage) {
      showErrorToast({ message: reportQueryErrorMessage });
    }
  }, [reportQueryErrorMessage, showErrorToast]);

  useEffect(() => {
    if (saveAsNewReportErrorMessage) {
      showErrorToast({ message: saveAsNewReportErrorMessage });
    }
  }, [saveAsNewReportErrorMessage, showErrorToast]);

  useEffect(() => {
    if (editReportErrorMessage) {
      showErrorToast({ message: editReportErrorMessage });
    }
  }, [editReportErrorMessage, showErrorToast]);

  useEffect(() => {
    if (deleteReportErrorMessage) {
      showErrorToast({ message: deleteReportErrorMessage });
    }
  }, [deleteReportErrorMessage, showErrorToast]);

  const handleClearAllClick = useCallback(() => {
    setReport((prevReport) => {
      if (!prevReport) {
        return prevReport;
      }

      return {
        ...prevReport,
        reportName: "New Report",
        reportGuid: null,
        selectedQueryOptions: getEmptySelectedQueryOptions(),
      };
    });
    shouldDisplayDefaultReportRef.current = false;
    navigate(generatePath(routes.reportingAdd.path));
  }, [navigate]);

  const handleReportSelectedQueryOptionsChange = useCallback(
    (selectedQueryOptions: ReportSelectedQueryOptions) => {
      reportQueryRequest(
        "/reports/query?qo=1",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: {
            reportName: report?.reportName,
            reportGuid: report?.reportGuid,
            createdTimestamp: report?.createdTimestamp,
            createdByUserName: report?.createdByUserName,
            selectedQueryOptions,
          },
        },
        {
          onSuccess: (reportData) => {
            setReport({ ...reportData });
          },
        }
      );
    },
    [
      report?.createdByUserName,
      report?.createdTimestamp,
      report?.reportGuid,
      report?.reportName,
      reportQueryRequest,
    ]
  );

  const handleDownloadClick = useCallback(() => {
    downloadReportRequest("/reports/query?qo=1&d=1", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      responseType: "blob",
      data: {
        reportName: report?.reportName,
        reportGuid: report?.reportGuid,
        createdTimestamp: report?.createdTimestamp,
        createdByUserName: report?.createdByUserName,
        selectedQueryOptions: report?.selectedQueryOptions,
      },
    });
  }, [
    downloadReportRequest,
    report?.createdByUserName,
    report?.createdTimestamp,
    report?.reportGuid,
    report?.reportName,
    report?.selectedQueryOptions,
  ]);

  const handleSaveReportClick = useCallback(
    (reportGuid: string) => {
      editReportRequest(
        `reports/saved/${reportGuid}/edit`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: {
            reportName: report?.reportName,
            selectedQueryOptions: report?.selectedQueryOptions,
          },
        },
        {
          onSuccess: (editReportData) => {
            refreshSavedReports();
            setReport((prevReport) => {
              if (prevReport == null) {
                return prevReport;
              }
              return {
                ...prevReport,
                ...editReportData,
              };
            });
          },
        }
      );
    },
    [
      editReportRequest,
      report?.reportName,
      report?.selectedQueryOptions,
      refreshSavedReports,
    ]
  );

  const handleSaveAsNewClick = useCallback(() => {
    saveAsNewReportRequest(
      `reports/save`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: {
          reportName: report?.reportName,
          selectedQueryOptions: report?.selectedQueryOptions,
        },
      },
      {
        onSuccess: (saveAsNewReportData) => {
          refreshSavedReports();
          setReport((prevReport) => {
            if (prevReport == null) {
              return prevReport;
            }
            return {
              ...prevReport,
              ...saveAsNewReportData,
            };
          });
          shouldDisplayDefaultReportRef.current = false;
          navigate(
            generatePath(routes.reportingEdit.path, {
              reportGuid: saveAsNewReportData.reportGuid,
            })
          );
        },
      }
    );
  }, [
    navigate,
    refreshSavedReports,
    report?.reportName,
    report?.selectedQueryOptions,
    saveAsNewReportRequest,
  ]);

  const handleReportNameUpdated = useCallback((updatedReport: Report) => {
    setReport((prevReport) => ({
      ...prevReport,
      ...updatedReport,
    }));
  }, []);

  const handleViewReport = useCallback(
    (reportGuid: string) => {
      shouldDisplayDefaultReportRef.current = false;
      navigate(
        generatePath(routes.reportingEdit.path, { reportGuid: reportGuid })
      );
    },
    [navigate]
  );

  const handleDeleteReport = useCallback(
    (deletedReportGuid: string, onDeleteSuccess?: () => void) => {
      deleteReportRequest(
        `reports/saved/${deletedReportGuid}/delete`,
        {
          method: "DELETE",
        },
        {
          onSuccess: () => {            
            refreshSavedReports();
            if (deletedReportGuid === reportGuid) {
              setReport((prevReport) => {
                if (prevReport == null) {
                  return prevReport;
                }
                return {
                  ...prevReport,
                  reportName: "New Report",
                  reportGuid: null,
                };
              });
              shouldDisplayDefaultReportRef.current = true;
              navigate(generatePath(routes.reportingAdd.path));
              if (onDeleteSuccess) onDeleteSuccess();
            }
          },
        }
      );
    },
    [deleteReportRequest, navigate, refreshSavedReports, reportGuid]
  );

  const canEditOrDelete = useMemo(
    () =>
      (savedReportsData ?? []).some(
        (savedReport) =>
          savedReport.reportGuid === report?.reportGuid &&
          savedReport.canEditOrDelete
      ),
    [report?.reportGuid, savedReportsData]
  );

  if (!report || !defaultReportData) {
    return null;
  }

  return (
    <>
      <ReportingContent
        report={report}
        canEditOrDelete={canEditOrDelete}
        sidebarWidth={sidebarWidth}
        isReportDownloadDisabled={downloadReportLoading}
        isReportSaveDisabled={
          saveAsNewReportLoading || editReportLoading || reportLoading
        }
        onReportDownloadClick={handleDownloadClick}
        onReportNameUpdated={handleReportNameUpdated}
        onSaveReportClick={handleSaveReportClick}
        onSaveAsNewClick={handleSaveAsNewClick}
      />
      <ReportingRightSidebar
        report={report}
        queryOptions={defaultReportData.queryOptions}
        savedReports={savedReportsData ?? []}
        selectedReportGuid={reportGuid}
        sidebarWidth={sidebarWidth}
        onClearAllClick={handleClearAllClick}
        onReportSelectedQueryOptionsChange={
          handleReportSelectedQueryOptionsChange
        }
        onViewReport={handleViewReport}
        onDeleteReport={handleDeleteReport}
      />
    </>
  );
};

export default ReportingAddEditRoute;
