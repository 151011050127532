import React, { useMemo } from "react";
import { OrderCard } from "../../types/orderCard";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import SafetyCheckOutlinedIcon from "@mui/icons-material/SafetyCheckOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ContrastIcon from "@mui/icons-material/Contrast";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Order } from "../../types/order";
import { OrderCardType, orderCardTypes } from "../../types/orderCardTypes";
import { orderCardSubgroupOptionHasLogicErrors } from "../../types/orderCardSubgroupOption";

type OrderCardNavMenuItemProps = {
  order: Order;
  orderCards: OrderCard[];
  boldedOrderCardGuid: string | undefined;
  onLinkClicked: (orderCardGuid: string) => void;
};

export const OrderCardNavMenuItem: React.FC<OrderCardNavMenuItemProps> = ({
  order,
  orderCards,
  boldedOrderCardGuid,
  onLinkClicked,
}) => {
  const categoryName = orderCards[0].categoryName;

  const isCategoryDisplayed = useMemo(
    () => orderCards.some((oc) => oc.orderCardGuid === boldedOrderCardGuid),
    [boldedOrderCardGuid, orderCards]
  );

  const categoryStatus = useMemo(
    () => getCategoryStatus(order, orderCards),
    [order, orderCards]
  );

  return (
    <React.Fragment key={categoryName}>
      <ListItemButton
        selected={isCategoryDisplayed}
        onClick={() => onLinkClicked(orderCards[0].orderCardGuid)}
      >
        <ListItemIcon sx={{ minWidth: 3, marginRight: 1 }}>
          {categoryStatus === "needsApproval" ? (
            <SafetyCheckOutlinedIcon color="primary" />
          ) : categoryStatus === "error" ? (
            <ErrorOutlineIcon sx={{ color: "#E31B54" }} />
          ) : categoryStatus === "incomplete" ? (
            <ContrastIcon sx={{ color: "#F79009" }} />
          ) : categoryStatus === "complete" ? (
            <CheckCircleOutlineOutlinedIcon color="success" />
          ) : categoryStatus === "notSet" ? (
            <ContrastIcon sx={{ color: "#F79009" }} />
          ) : null}
        </ListItemIcon>
        <ListItemText
          primary={categoryName}
          primaryTypographyProps={{ fontSize: 16 }}
        />
      </ListItemButton>
      {isCategoryDisplayed
        ? orderCards.map((orderCard, orderCardIndex) => {
            const prevOrderCard = orderCards[orderCardIndex - 1];

            // If we've already added this group, skip it..
            if (prevOrderCard?.groupName === orderCard.groupName) {
              return null;
            }

            const isGroupBolded =
              orderCard.orderCardGuid === boldedOrderCardGuid;

            return (
              <ListItemButton
                key={`${categoryName}${orderCard.groupName}`}
                onClick={() => onLinkClicked(orderCard.orderCardGuid)}
              >
                <ListItemText
                  primary={orderCard.groupName}
                  sx={{
                    marginLeft: 4,
                  }}
                  primaryTypographyProps={{
                    fontWeight: isGroupBolded ? 600 : undefined,
                  }}
                />
              </ListItemButton>
            );
          })
        : null}
    </React.Fragment>
  );
};

type CategoryStatus =
  | "needsApproval"
  | "error"
  | "incomplete"
  | "complete"
  | "notSet";

const getCategoryStatus = (
  order: Order,
  orderCards: OrderCard[]
): CategoryStatus => {
  const statusRank: Record<CategoryStatus, number> = {
    needsApproval: 0,
    error: 1,
    incomplete: 2,
    complete: 3,
    notSet: 4,
  };

  const categoryStatus: CategoryStatus = orderCards.reduce<CategoryStatus>(
    (currentCategoryStatus, orderCard) => {
      let orderCardStatus: CategoryStatus = "notSet";

      if (orderCardNeedsApproval(orderCard)) {
        orderCardStatus = "needsApproval";
      } else if (orderCardHasError(order, orderCard)) {
        orderCardStatus = "error";
      } else if (orderCardIsIncomplete(orderCard)) {
        orderCardStatus = "incomplete";
      } else if (orderCardIsComplete(orderCard)) {
        orderCardStatus = "complete";
      }

      const currentCategoryStatusRank = statusRank[currentCategoryStatus];
      const orderCardStatusRank = statusRank[orderCardStatus];

      return currentCategoryStatusRank < orderCardStatusRank
        ? currentCategoryStatus
        : orderCardStatus;
    },
    "notSet"
  );

  return categoryStatus;
};

const orderCardNeedsApproval = (orderCard: OrderCard): boolean => {
  const orderCardTypesRequiringApproval: OrderCardType[] = [
    orderCardTypes.customSubgroup,
    orderCardTypes.subgroupOption,
    orderCardTypes.salesChangeOrder,
  ];

  if (!orderCardTypesRequiringApproval.includes(orderCard.orderCardType)) {
    return false;
  }

  return orderCard.orderCardStatus === "c" && orderCard.isApproved === false;
};

const orderCardHasError = (order: Order, orderCard: OrderCard): boolean => {
  if (orderCard.errors.length > 0) {
    return true;
  }

  const hasLogicError: boolean =
    orderCard.orderCardType === orderCardTypes.subgroupOption
      ? orderCardSubgroupOptionHasLogicErrors(orderCard)
      : false;

  return hasLogicError;
};

const orderCardIsIncomplete = (orderCard: OrderCard): boolean => {
  const isCustom: boolean =
    orderCard.orderCardType === orderCardTypes.customSubgroup ||
    orderCard.orderCardType === orderCardTypes.salesChangeOrder ||
    (orderCard.orderCardType === orderCardTypes.subgroupOption &&
      orderCard.cardData.customOptionSelected);

  return (
    (orderCard.isRequired === true || isCustom) &&
    orderCard.orderCardStatus === "i"
  );
};

const orderCardIsComplete = (orderCard: OrderCard): boolean => {
  return !orderCard.isRequired || orderCard.orderCardStatus === "c";
};
