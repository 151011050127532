import React, { useEffect } from "react";
import { type OrderCardAddAppliance as OrderCardAddApplianceType } from "../../types/orderCardAddAppliance";
import { OrderCardActionButton } from "./OrderCardActionButton";
import { OrderCardBase } from "./OrderCardBase";
import { Order } from "../../types/order";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { useSession } from "../../hooks/useSession";

interface OrderCardAddApplianceProps {
  order: Order;
  orderCard: OrderCardAddApplianceType;
  expanded: boolean;
  groupIndex: number;
  refreshOrder: () => void;
}

export const OrderCardAddAppliance: React.FC<OrderCardAddApplianceProps> = ({
  order,
  orderCard,
  expanded,
  groupIndex,
  refreshOrder,
}) => {
  const disabled = orderCard.isLocked;
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { request, data, loading, errorMessage } = useApiRequest();
  const { user } = useSession();

  const handleAddApplianceClick = () => {
    request(`/orders/${order.orderGuid}/appliances/add`, {
      method: "GET",
    });
  };

  useEffect(() => {
    if (errorMessage) {
      showErrorToast({ message: errorMessage });
    }
  }, [errorMessage, showErrorToast]);

  useEffect(() => {
    if (data) {
      refreshOrder();
    }
  }, [data, refreshOrder]);

  return (
    <>
      <OrderCardBase
        disabled={disabled}
        orderCard={orderCard}
        expanded={expanded}
        groupIndex={groupIndex}
        action={
          ((!user?.isRetailUser && !order?.isUnconfirmed) && 
            <OrderCardActionButton
            onClick={handleAddApplianceClick}
            disabled={disabled || loading}
          >
            Add Appliance
          </OrderCardActionButton>
          )
        }
      />
    </>
  );
};
