import { Typography } from "@mui/material";
import { useEffect } from "react";
import { ConfirmModal } from "./ConfirmModal";
import { useGlobalToastNotificationContext } from "../context/GlobalToastNotificationContext";
import { useApiRequest } from "../hooks/useApiRequest";

type UnconfirmOrderModalProps = {
  orderGuid: string;
  onClose: () => void;
  onUnconfirmSuccess: () => void;
};

export const UnconfirmOrderModal: React.FC<UnconfirmOrderModalProps> = ({
  orderGuid,
  onClose,
  onUnconfirmSuccess,
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();

  const {
    data: unconfirmOrderData,
    request: unconfirmOrderRequest,
    loading: unconfirmOrderLoading,
    status: unconfirmOrderStatus,
    errorMessage: unconfirmOrderErrorMessage,
  } = useApiRequest(false);

  useEffect(() => {
    if (
      unconfirmOrderData &&
      unconfirmOrderStatus === "ok" &&
      unconfirmOrderLoading === false
    ) {
      onUnconfirmSuccess();
    }
  }, [unconfirmOrderData, unconfirmOrderLoading, unconfirmOrderStatus, onUnconfirmSuccess]);

  useEffect(() => {
    if (unconfirmOrderErrorMessage) {
      showErrorToast({ message: unconfirmOrderErrorMessage });
    }
  }, [unconfirmOrderErrorMessage, showErrorToast]);

  const handleUnconfirmClick = () => {
    unconfirmOrderRequest(`/orders/${orderGuid}/unconfirm`, {
      method: "POST",
      data: { resetPriceData: false },
    });
  };

  return (
    <ConfirmModal
      isOpen={true}
      heading="Unconfirm this order?"
      body={
        <Typography variant="body2" color="grey.500">
          Unconfirming an order moves it back to a Quote. Invoice and Appliance data will be retained, as will Sales Change Orders. Dates such as the Date of Manufacture will be preserved.
        </Typography>
      }
      confirmButtonText="Unconfirm"
      isDestructive={false}
      onConfirm={handleUnconfirmClick}
      onClose={onClose}
      confirmDisabled={unconfirmOrderLoading}
    />
  );
};
