import {
  Avatar,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import {
  Attribute,
  getAttributePresentAsDisplayName,
} from "../../types/attribute";
import {
  AttributeSelection,
  getAttributeSelectionImageUrl,
} from "../../types/attributeSelection";
import { formatAsCurrency } from "../../utils/number";
import { formatAsDate } from "../../utils/date";
import { AccordionCardDraggable } from "../../components/AccordionCardDraggable";
import { useSession } from "../../hooks/useSession";

type AttributeCardProps = {
  attribute: Attribute;
  onAttributeEdit?: (attribute: Attribute) => void;
  onRemove?: (attribute: Attribute) => void;
  onAttributeSelectionAdd?: (attribute: Attribute) => void;
  onAttributeSelectionEdit?: (
    attribute: Attribute,
    selection: AttributeSelection
  ) => void;
  onAttributeSelectionDiscontinue?: (
    attribute: Attribute,
    selection: AttributeSelection
  ) => void;
  expanded: boolean;
  onToggleExpand?: (attributeGuid: string) => void;
};

export const AttributeCard: React.FC<AttributeCardProps> = ({
  attribute,
  onAttributeEdit,
  onRemove,
  onAttributeSelectionAdd,
  onAttributeSelectionEdit,
  onAttributeSelectionDiscontinue,
  expanded,
  onToggleExpand,
}) => {
  const { user } = useSession();
  return (
    <>
      <AccordionCardDraggable
        dragId={attribute.attributeGuid}
        expanded={expanded}
        onToggleExpand={() =>
          onToggleExpand && onToggleExpand(attribute.attributeGuid)
        }
        key={attribute.attributeGuid}
        accordionSx={{
          border: "none",
        }}
        summarySx={(theme) => ({
          backgroundColor: theme.palette.grey[50],
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
        })}
        headingHtml={
          <Stack>
            <Typography variant="body1" fontWeight="bold">
              {attribute.attributeName}
            </Typography>
            <Typography variant="body2">
              {attribute.attributeSelectIsRequired ? "Required " : ""}
              {getAttributePresentAsDisplayName(attribute.attributePresentAs)}
              {` with ${attribute.attributeselections.length} Options`}
            </Typography>
          </Stack>
        }
        endActions={
          user?.isRetailUser ? null :
          <MoreMenuButton
            menuItems={[
              {
                label: "Edit",
                onClick: () => {
                  if (onAttributeEdit)
                    onAttributeEdit(attribute);
                },
              },
              {
                label: "Remove",
                onClick: () => {
                  if (onRemove)
                    onRemove(attribute);
                },
              },
            ]}
          />
        }
      >
        <Stack spacing={2}>
          <Card variant="outlined">
            {!user?.isRetailUser && (
              <Button
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => {
                  if (onAttributeSelectionAdd)
                    onAttributeSelectionAdd(attribute);
                }}
              >
                Attribute Selection
              </Button>
            )}
            <CardContent>
              {attribute.attributeselections.map((selection) => (
                <Stack
                  direction="row"
                  spacing={2}
                  mb={2}
                  key={selection.attributeselectionGuid}
                  justifyContent="space-between"
                >
                  <Stack direction="row" spacing={2}>
                    {attribute?.attributeIncludeImages ? (
                      <Avatar src={getAttributeSelectionImageUrl(selection)} />
                    ) : null}
                    <Stack>
                      <Typography variant="body1" fontWeight="bold">
                        {selection.attributeselectionName}
                      </Typography>
                      <Typography variant="body2">
                        {`+${formatAsCurrency(
                          selection.attributeselectionPrice
                        )} `}
                        &#x2022;
                        {` ${formatAsDate(selection.startDate)}`}
                        {` - `}
                        {selection.endDate
                          ? formatAsDate(selection.endDate)
                          : "Current"}
                      </Typography>
                    </Stack>
                  </Stack>
                  {!user?.isRetailUser && (
                    <MoreMenuButton
                      menuItems={[
                        {
                          label: "Edit",
                          onClick: () => {
                            if (onAttributeSelectionEdit)
                              onAttributeSelectionEdit(attribute, selection);
                          },
                        },
                        {
                          label: "Discontinue",
                          onClick: () => {
                            if (onAttributeSelectionDiscontinue)
                              onAttributeSelectionDiscontinue(attribute, selection);
                          },
                        },
                      ]}
                    />
                  )}
                </Stack>
              ))}
            </CardContent>
          </Card>
        </Stack>
      </AccordionCardDraggable>
    </>
  );
};
