import React from "react";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Stack,
  Card,
  IconButton,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useSession } from "../../hooks/useSession";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import InputField from "../../components/InputField";
import { AvatarUpload } from "../../components/AvatarUpload";
import { isValidPhoneNumber } from "../../utils/validation";
import { routes } from "../../routes";
import { PageHeader } from "../../components/PageHeader";
import { FormSection } from "../../components/FormSection";
import { Tenant } from "../../types/tenant";
import { getDirtyFormValues } from "../../utils/form";
import {
  getMarkupDisplayName,
  getMarkupTypeDisplayName,
  getMarkupTypeOptions,
  Markup,
} from "../../types/markup";
import SelectField from "../../components/SelectField";
import SwitchField from "../../components/SwitchField";
import { Popover } from "../../components/Popover";
import { InfoOutlined } from "@mui/icons-material";

type FormData = {
  tenantName: string;
  retailerFriendlyName?: string;
  retailerEinTin?: string;
  retailerLicenseNumber?: string;
  tenantPhoneNumber?: string;
  tenantWebsite?: string;
  tenantAddress1?: string;
  tenantAddress2?: string;
  tenantCity?: string;
  tenantState?: string;
  tenantZip?: string;
  retailerShippingAddress1?: string;
  retailerShippingAddress2?: string;
  retailerShippingCity?: string;
  retailerShippingState?: string;
  retailerShippingZip?: string;
  tenantStartingInvoiceNumber?: string;
  markup: Markup[] | null;
  applyPercentToConstCosts: boolean;
  includeReqHidConstCostsInHomePrice: boolean;
  hudFee?: number;
  modFee?: number;
};

const getDefaultValues = (tenant: Tenant | undefined): FormData => {
  return {
    tenantName: tenant?.tenantName ?? "",
    retailerFriendlyName: tenant?.retailerFriendlyName ?? "",
    retailerEinTin: tenant?.retailerEinTin ?? "",
    retailerLicenseNumber: tenant?.retailerLicenseNumber ?? "",
    tenantPhoneNumber: tenant?.tenantPhoneNumber ?? "",
    tenantWebsite: tenant?.tenantWebsite ?? "",
    tenantAddress1: tenant?.tenantAddress1 ?? "",
    tenantAddress2: tenant?.tenantAddress2 ?? "",
    tenantCity: tenant?.tenantCity ?? "",
    tenantState: tenant?.tenantState ?? "",
    tenantZip: tenant?.tenantZip ?? "",
    retailerShippingAddress1: tenant?.retailerShippingAddress1 ?? "",
    retailerShippingAddress2: tenant?.retailerShippingAddress2 ?? "",
    retailerShippingCity: tenant?.retailerShippingCity ?? "",
    retailerShippingState: tenant?.retailerShippingState ?? "",
    retailerShippingZip: tenant?.retailerShippingZip ?? "",
    tenantStartingInvoiceNumber: tenant?.tenantStartingInvoiceNumber ?? "",
    markup: tenant?.markup ?? null,
    applyPercentToConstCosts: tenant?.applyPercentToConstCosts ?? false,
    includeReqHidConstCostsInHomePrice:
      tenant?.includeReqHidConstCostsInHomePrice ?? false,
    hudFee: tenant?.hudFee,
    modFee: tenant?.modFee,
  };
};

function ManageCompanyRoute() {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { showSuccessToast, showErrorToast } =
    useGlobalToastNotificationContext();
  const [tenant, setTenant] = useState<Tenant>();
  const { user } = useSession();
  const {
    data: tenantUpdateData,
    loading: tenantUpdateLoading,
    request: tenantUpdateRequest,
    status: tenantUpdateStatus,
    errorMessage: tenantUpdateErrorMessage,
  } = useApiRequest<Tenant>(true, "tenant");
  const { data: tenantData, request: tenantRequest } = useApiRequest<Tenant>();

  const { handleSubmit, register, formState, control, reset, watch } =
    useForm<FormData>({
      defaultValues: getDefaultValues(tenant),
      reValidateMode: "onChange",
      mode: "onChange",
    });

  const { fields } = useFieldArray({
    control,
    name: "markup",
  });

  const markupTypes = watch("markup");

  const isOwner = useMemo(() => user?.userRole === "owner", [user?.userRole]);

  usePageMetadata({ title: routes.manageDataCompany.label });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataCompany.label }]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    tenantRequest("/company");
  }, [tenantRequest]);

  useEffect(() => {
    if (
      tenantUpdateData &&
      tenantUpdateStatus === "ok" &&
      tenantUpdateLoading === false
    ) {
      showSuccessToast({ message: "Company Information Saved" });
    } else if (tenantUpdateErrorMessage != null) {
      showErrorToast({ message: tenantUpdateErrorMessage });
    }
  }, [
    showErrorToast,
    showSuccessToast,
    tenantUpdateData,
    tenantUpdateErrorMessage,
    tenantUpdateLoading,
    tenantUpdateStatus,
  ]);

  useEffect(() => {
    if (tenantUpdateData) {
      setTenant(tenantUpdateData);
      const defaultValues = getDefaultValues(tenantUpdateData);
      reset(defaultValues, { keepDirtyValues: true });
    }
  }, [tenantUpdateData, reset]);

  useEffect(() => {
    if (tenantData) {
      setTenant(tenantData);
      const defaultValues = getDefaultValues(tenantData);
      reset(defaultValues);
    }
  }, [tenantData, reset]);

  const onSubmit = (values: FormData) => {
    const data = getDirtyFormValues<FormData>(values, formState);

    tenantUpdateRequest("/company/edit", {
      method: "POST",
      data,
    });
  };

  const handleCancelClick = () => {
    const defaultValues = getDefaultValues(tenant);
    reset(defaultValues);
  };

  const handleSelectFile = (file: File) => {
    tenantUpdateRequest("/company/edit", {
      method: "POST",
      data: { tenantPhoto: file },
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isOwner && (
          <PageHeader
            actions={
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                marginBottom={1}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={
                    !formState.isValid ||
                    !formState.isDirty ||
                    tenantUpdateLoading
                  }
                >
                  Save
                </Button>
              </Stack>
            }
          />
        )}
        <Card elevation={0} sx={{ padding: 4 }}>
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            width="100%"
            spacing={{ xs: 2, sm: 2, md: 6 }}
          >
            <AvatarUpload
              readOnly={!isOwner}
              alt="Company Image"
              src={
                tenant && tenant.tenantPhotoUrl
                  ? `${process.env.REACT_APP_API_BASE_URL}/${tenant.tenantPhotoUrl}`
                  : undefined
              }
              variant="rounded"
              onSelectFile={handleSelectFile}
            />
            <Grid>
              <FormSection heading="Info">
                <Grid xs={12} sm={12} md={6}>
                  <InputField
                    {...register("tenantName", {
                      required: {
                        value: true,
                        message: "Please enter a value.",
                      },
                    })}
                    disabled={!isOwner}
                    fullWidth
                    required
                    label="Company Name"
                    error={!!formState.errors.tenantName}
                    helperText={formState.errors.tenantName?.message}
                  />
                </Grid>
                {user?.isRetailUser && (
                  <>
                    <Grid xs={12} sm={12} md={6}>
                      <InputField
                        {...register("retailerFriendlyName")}
                        disabled={!isOwner}
                        fullWidth
                        label="Other Name"
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6}>
                      <InputField
                        {...register("retailerEinTin")}
                        disabled={!isOwner}
                        fullWidth
                        label="EIN / TIN"
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6}>
                      <InputField
                        {...register("retailerLicenseNumber")}
                        disabled={!isOwner}
                        fullWidth
                        label="License Number"
                      />
                    </Grid>
                  </>
                )}
              </FormSection>
              <FormSection heading="Contact">
                <Grid xs={12} sm={12} md={6}>
                  <InputField
                    {...register("tenantPhoneNumber", {
                      validate: {
                        validPhoneNumber: (phoneNumberValue) =>
                          phoneNumberValue === "" ||
                          phoneNumberValue == null ||
                          isValidPhoneNumber(phoneNumberValue) ||
                          "Please enter a valid phone number.",
                      },
                    })}
                    disabled={!isOwner}
                    fullWidth
                    label="Phone Number"
                    error={!!formState.errors.tenantPhoneNumber}
                    helperText={formState.errors.tenantPhoneNumber?.message}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <InputField
                    {...register("tenantWebsite")}
                    disabled={!isOwner}
                    fullWidth
                    label="Website"
                    error={!!formState.errors.tenantWebsite}
                    helperText={formState.errors.tenantWebsite?.message}
                  />
                </Grid>
              </FormSection>
              <FormSection heading={user?.isRetailUser ? "Business / Billing Address" : "Location"}>
                <Grid xs={12} sm={12} md={6}>
                  <InputField
                    {...register("tenantAddress1")}
                    disabled={!isOwner}
                    fullWidth
                    label="Address"
                    placeholder="Enter Address"
                    error={!!formState.errors.tenantAddress1}
                    helperText={formState.errors.tenantAddress1?.message}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <InputField
                    {...register("tenantAddress2")}
                    disabled={!isOwner}
                    fullWidth
                    label="Address 2"
                    placeholder="Enter Address"
                    error={!!formState.errors.tenantAddress2}
                    helperText={formState.errors.tenantAddress2?.message}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <InputField
                    {...register("tenantCity")}
                    disabled={!isOwner}
                    fullWidth
                    label="City"
                    placeholder="Enter City"
                    error={!!formState.errors.tenantCity}
                    helperText={formState.errors.tenantCity?.message}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <InputField
                    {...register("tenantState")}
                    disabled={!isOwner}
                    fullWidth
                    label="State"
                    placeholder="Enter State"
                    error={!!formState.errors.tenantState}
                    helperText={formState.errors.tenantState?.message}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <InputField
                    {...register("tenantZip")}
                    disabled={!isOwner}
                    fullWidth
                    label="ZIP"
                    placeholder="Enter ZIP"
                    error={!!formState.errors.tenantZip}
                    helperText={formState.errors.tenantZip?.message}
                  />
                </Grid>
              </FormSection>
              {user?.isRetailUser && (
                <>
                  <FormSection heading="Shipping Address">
                    <Grid xs={12} sm={12} md={6}>
                      <InputField
                        {...register("retailerShippingAddress1")}
                        disabled={!isOwner}
                        fullWidth
                        label="Address"
                        placeholder="Enter Shipping Address"
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6}>
                      <InputField
                        {...register("retailerShippingAddress2")}
                        disabled={!isOwner}
                        fullWidth
                        label="Address 2"
                        placeholder="Enter Shipping Address"
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6}>
                      <InputField
                        {...register("retailerShippingCity")}
                        disabled={!isOwner}
                        fullWidth
                        label="City"
                        placeholder="Enter Shipping City"
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6}>
                      <InputField
                        {...register("retailerShippingState")}
                        disabled={!isOwner}
                        fullWidth
                        label="State"
                        placeholder="Enter Shipping State"
                      />
                    </Grid>
                    <Grid xs={12} sm={12} md={6}>
                      <InputField
                        {...register("retailerShippingZip")}
                        disabled={!isOwner}
                        fullWidth
                        label="ZIP"
                        placeholder="Enter Shipping ZIP"
                      />
                    </Grid>
                  </FormSection>
                  {!user?.isRetailUser && (
                    <>
                      <FormSection heading="Billing">
                        <Grid xs={12} sm={12} md={6}>
                          <InputField
                            {...register("tenantStartingInvoiceNumber")}
                            disabled={!isOwner}
                            fullWidth
                            placeholder="Enter Starting Invoice Number"
                            label="Starting Invoice Number"
                            error={!!formState.errors.tenantStartingInvoiceNumber}
                            helperText={
                              formState.errors.tenantStartingInvoiceNumber?.message
                            }
                          />
                        </Grid>
                      </FormSection>
                      <FormSection heading="HUD and MOD Fees">
                        <Grid xs={12} sm={12} md={6}>
                          <InputField
                            {...register("hudFee")}
                            disabled={!isOwner}
                            fullWidth
                            placeholder="Enter HUD Fee (per section)"
                            label="HUD Fee (per section)"
                            startAdornment={
                              <InputAdornment position="start">$</InputAdornment>
                            }
                            type="number"
                            intOnly={true}
                            error={!!formState.errors.hudFee}
                            helperText={
                              formState.errors.hudFee?.message
                            }                        
                          />
                        </Grid>
                        <Grid xs={12} sm={12} md={6}>
                          <InputField
                            {...register("modFee")}
                            disabled={!isOwner}
                            fullWidth
                            placeholder="Enter MOD Fee (per unit)"
                            label="MOD Fee (per unit)"
                            startAdornment={
                              <InputAdornment position="start">$</InputAdornment>
                            }
                            type="number"
                            intOnly={true}
                            error={!!formState.errors.modFee}
                            helperText={
                              formState.errors.modFee?.message
                            }
                          />
                        </Grid>
                      </FormSection>
                    </>
                  )}
                </>
              )}              
              {user?.isRetailUser && (
                <FormSection heading="Markup">
                  <Grid container spacing={2}>
                    {fields.map((field, index) => (
                      <React.Fragment key={field.id}>
                        <Grid xs={12} md={6}>
                          <SelectField
                            {...register(`markup.${index}.type`, {
                              required: {
                                value: true,
                                message: "Please enter a value.",
                              },
                            })}
                            value={markupTypes && markupTypes[index]?.type}
                            options={getMarkupTypeOptions}
                            fullWidth
                            required
                            label={`${getMarkupDisplayName(
                              field.markup
                            )} Markup`}
                            error={!!formState.errors.markup?.[index]?.value}
                            helperText={
                              formState.errors.markup?.[index]?.value?.message
                            }
                          />
                        </Grid>
                        <Grid xs={12} md={6}>
                          <InputField
                            {...register(`markup.${index}.value`, {
                              valueAsNumber: true,
                              required: {
                                value: true,
                                message: "Please enter a value.",
                              },
                              validate: {
                                isNumber: (value) => {
                                  return (
                                    !isNaN(value) ||
                                    "Please enter a valid number."
                                  );
                                },
                              },
                            })}
                            fullWidth
                            required
                            label={`${getMarkupDisplayName(
                              field.markup
                            )} ${getMarkupTypeDisplayName(
                              markupTypes?.[index]?.type ?? "f"
                            )}`}
                            placeholder="Enter Markup"
                            error={!!formState.errors.markup?.[index]?.value}
                            helperText={
                              formState.errors.markup?.[index]?.value?.message
                            }
                            startAdornment={
                              markupTypes?.[index]?.type === "f" ? (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ) : null
                            }
                            endAdornment={
                              markupTypes?.[index]?.type === "p" ? (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ) : null
                            }
                            intOnly={markupTypes?.[index]?.type === "f"}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                    <Stack spacing={2}>
                      <Controller
                        name="applyPercentToConstCosts"
                        control={control}
                        render={({ field }) => (
                          <SwitchField
                            {...field}
                            label="Apply percent profit markup to construction costs"
                            checked={field.value}
                            sx={{ marginRight: "auto" }}
                          />
                        )}
                      />
                      <Stack direction="row">
                        <Controller
                          name="includeReqHidConstCostsInHomePrice"
                          control={control}
                          render={({ field }) => (
                            <SwitchField
                              {...field}
                              label="Include Required (Hidden) Construction Costs in Home Price"
                              checked={field.value}
                              sx={{ marginRight: "auto" }}
                            />
                          )}
                        />
                        <Popover
                          button={
                            <IconButton>
                              <InfoOutlined sx={{ color: "#344054" }} />
                            </IconButton>
                          }
                          body={
                            <Box>
                              <Typography variant="body1">
                                When not selected, Required (Hidden)
                                Construction Costs will be shown with other
                                Construction Costs.
                              </Typography>
                            </Box>
                          }
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </FormSection>
              )}
            </Grid>
          </Stack>
        </Card>
      </form>
    </>
  );
}

export default ManageCompanyRoute;
