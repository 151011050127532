import { ModelRoom } from "./modelRoom";
import { Section } from "./section";

export type Model = {
  modelGuid: string;
  modelName: string;
  modelNumber: string;
  seriesGuid: string;
  sortOrder: number;
  startDate: number;
  endDate: number | null;
  modelBasePrice: number;
  modelConstructionType: "m" | "d";
  modelRooms: ModelRoom[];
  sections: Section[];
};

export type ModelConstructionType = Model["modelConstructionType"];

export const getStartDateFormatted = (model: Pick<Model, "startDate">) => {
  const startDate = new Date(model.startDate * 1000);
  const formattedStartDate = startDate.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  return formattedStartDate;
};

export const getEndDateFormatted = (model: Pick<Model, "endDate">) => {
  if (!model.endDate) {
    return "Current";
  }

  const endDate = new Date(model.endDate * 1000);
  const formattedEndDate = endDate.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  return formattedEndDate;
};

export const getModelConstructionTypeDisplayName = (
  modelConstructionType: ModelConstructionType
): string => {
  const displayNames: Record<ModelConstructionType, string> = {
    m: "Manufactured Home",
    d: "Modular",
  };

  return displayNames[modelConstructionType] || modelConstructionType;
};

export const getModelBuildCodeDisplayName = (
  modelConstructionType: ModelConstructionType
): string => {
  const displayNames: Record<ModelConstructionType, string> = {
    m: "HUD",
    d: "MOD",
  };

  return displayNames[modelConstructionType] || modelConstructionType;
};
