import { useEffect, useMemo } from "react";
import { Grid } from "@mui/material";
import { FormSection } from "../../components/FormSection";
import { FormData } from "./FormData";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import InputField from "../../components/InputField";
import { DatePicker } from "../../components/DatePicker";
import MultiSelectField from "../../components/MultiSelectField";
import { roomOptions } from "../../types/optionRoom";
import { isValidNumber } from "../../utils/validation";
import SwitchField from "../../components/SwitchField";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Subgroup } from "../../types/subgroup";

interface OptionDetailsFormSectionProps {
  errors: FieldErrors<FormData>;
  register: UseFormRegister<FormData>;
  control: Control<FormData>;
  trigger: UseFormTrigger<FormData>;
  setValue: UseFormSetValue<FormData>;
  optionRooms: string[];
  subgroupGuid: string;
  selectedSeries: string[];
  allDisabled: boolean;
}

export const OptionDetailsFormSection: React.FC<
  OptionDetailsFormSectionProps
> = ({
  errors,
  register,
  control,
  trigger,
  setValue,
  optionRooms,
  subgroupGuid,
  selectedSeries,
  allDisabled,
}) => {
  const { data: subgroupData, request: subgroupRequest } =
    useApiRequest<Subgroup>(false);

  useEffect(() => {
    subgroupRequest(`/company/options/subgroups/${subgroupGuid}`, {
      method: "GET",
    });  
  },[subgroupRequest,subgroupGuid]);

  const seriesOptions = useMemo(() => {
    if (!subgroupData?.series.length) {
      return [];
    }

    const selectedSeriesData = subgroupData.series.filter((s) =>
      selectedSeries.includes(s.seriesGuid)
    );

    // Allow for a deactivated series to show up in the list so that the user
    // may remove it. But don't add it to the list otherwise
    const filteredSubgroupSeries = subgroupData.series.filter(
      (s) => selectedSeries.includes(s.seriesGuid) || s.isActive
    );

    const combinedSeries = [...filteredSubgroupSeries, ...selectedSeriesData];

    // Deduplication step
    const uniqueSeries = Array.from(
      new Map(combinedSeries.map((s) => [s.seriesGuid, s])).values()
    );

    return uniqueSeries.map((s) => ({
      label: s.seriesName,
      value: s.seriesGuid,
    }));
  }, [subgroupData, selectedSeries]);

  return (
    <>
      <FormSection heading="Option Details">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <InputField
              {...register("optionName", {
                required: {
                  value: true,
                  message: "Please enter a value.",
                },
              })}
              fullWidth
              required
              disabled={allDisabled}
              label="Option Name"
              placeholder="Enter option name"
              error={!!errors.optionName}
              helperText={errors.optionName?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: "Enter Start Date",
                validate: (startDate, fields) => {
                  if (!startDate) {
                    return "Start Date is required";
                  }
                  if (startDate && !fields.endDate) {
                    return true;
                  }
                  if (fields.endDate && startDate > fields.endDate) {
                    return "Start Date must be before End Date";
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <DatePicker
                  label="Start Date"
                  fullWidth
                  {...field}
                  required
                  error={!!errors.startDate}
                  helperText={errors.startDate?.message}
                  disabled={allDisabled}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("endDate");
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="endDate"
              control={control}
              rules={{
                validate: (endDateValue, fields) => {
                  if (
                    endDateValue &&
                    fields.startDate &&
                    fields.startDate > endDateValue
                  ) {
                    return "End Date must be after Start Date";
                  }
                  return true;
                },
              }}
              render={({ field }) => (
                <DatePicker
                  label="End Date"
                  required
                  fullWidth
                  allowNoDate
                  {...field}
                  error={!!errors.endDate}
                  helperText={errors.endDate?.message}
                  disabled={allDisabled}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("startDate");
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              {...register("optionMinimum", {
                onChange: () => {
                  trigger("optionMaximum");
                },
                valueAsNumber: true,
                required: {
                  value: true,
                  message: "Please enter a minimum.",
                },
                validate: {
                  isLess: (optionMinimum, { optionMaximum }) => {
                    if (!optionMinimum || !optionMaximum) return true;
                    return (
                      optionMaximum >= optionMinimum ||
                      "Minimum must be less than or equal to Maximum."
                    );
                  },
                  isNumber: (value) => {
                    return isValidNumber(value) || "Please enter a number.";
                  },
                  isWholeNumber: (value) => {
                    return (
                      Number.isInteger(value) || "Please enter a whole number."
                    );
                  },
                  greaterThanZero: (value) => {
                    if (value == null) return true;
                    return value > 0 || "Please enter a number greater than 0.";
                  },
                },
              })}
              fullWidth
              required
              disabled={allDisabled}
              label="Minimum"
              placeholder="Enter Minimum"
              error={!!errors.optionMinimum}
              helperText={errors.optionMinimum?.message}
              intOnly={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              {...register("optionMaximum", {
                onChange: () => {
                  trigger("optionMinimum");
                },
                valueAsNumber: true,
                required: {
                  value: true,
                  message: "Please enter a maximum.",
                },
                validate: {
                  isGreater: (optionMaximum, { optionMinimum }) => {
                    if (!optionMinimum || !optionMaximum) return true;
                    return (
                      optionMaximum >= optionMinimum ||
                      "Maximum must be greater than or equal to Minimum."
                    );
                  },
                  isNumber: (value) => {
                    return isValidNumber(value) || "Please enter a number.";
                  },
                  isWholeNumber: (value) => {
                    return (
                      Number.isInteger(value) || "Please enter a whole number."
                    );
                  },
                  greaterThanZero: (value) => {
                    if (value == null) return true;
                    return value > 0 || "Please enter a number greater than 0.";
                  },
                },
              })}
              fullWidth
              required
              disabled={allDisabled}
              label="Maximum"
              placeholder="Enter Maximum"
              error={!!errors.optionMaximum}
              helperText={errors.optionMaximum?.message}
              intOnly={true}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              control={control}
              name="series"
              rules={{ required: "Please select a value." }}
              render={({ field, fieldState }) => (
                <MultiSelectField
                  {...field}
                  label="Applicable Series"
                  placeholder="Select applicable series"
                  required
                  disabled={allDisabled}
                  fullWidth
                  options={seriesOptions}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onDelete={(option) => {
                    const newOptionSeries = selectedSeries.filter(
                      (s) => s !== option.value
                    );
                    setValue("series", newOptionSeries, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              control={control}
              name="optionRooms"
              rules={{ required: "Please select a value." }}
              render={({ field, fieldState }) => (
                <MultiSelectField
                  {...field}
                  label="Applicable Rooms"
                  placeholder="Select applicable rooms"
                  required
                  disabled={allDisabled}
                  fullWidth
                  options={roomOptions}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onDelete={(option) => {
                    const newOptionRooms = optionRooms.filter(
                      (s) => s !== option.value
                    );
                    setValue("optionRooms", newOptionRooms, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="uniqueAttributesPerRoom"
              control={control}
              render={({ field }) => (
                <SwitchField
                  {...field}
                  label="Unique attributes per room"
                  checked={field.value}
                  disabled={allDisabled}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <InputField
              {...register("optionDisclaimer", {})}
              fullWidth
              label="Disclaimer Popup"
              placeholder="Enter Disclaimer"
              error={!!errors.optionDisclaimer}
              helperText={errors.optionDisclaimer?.message}
              multiline
              disabled={allDisabled}
              rows={3}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  );
};
