import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { PageHeader } from "../../components/PageHeader";
import { EmptyState } from "../../components/EmptyState";
import {
  Button,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  Option,
  OptionPricedPerType,
  getOptionPricePerDisplayName,
} from "../../types/option";
import { TableDraggable } from "../../components/TableDraggable";
import { GridColDef } from "@mui/x-data-grid";
import { Subgroup } from "../../types/subgroup";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { InfoPanel } from "../../components/InfoPanel";
import { getSeriesName, Series } from "../../types/series";
import { formatAsDate } from "../../utils/date";
import { FilterSearchDateRangeButton } from "../../components/FilterSearchDateRangeButton";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import Search from "@mui/icons-material/Search";
import { DateTime } from "luxon";
import { updateSortOrder } from "../../utils/array";
import { NewEditSubGroupModal } from "../ManageSelectionOptionsRoute/NewEditSubGroupModal";
import { DeleteOptionModal } from "../../components/DeleteOptionModal";
import { useSession } from "../../hooks/useSession";
import { Table } from "../../components/Table";

const ManageSelectionOptionsSubgroupRoute: React.FC = () => {
  const { user } = useSession();
  const [options, setOptions] = useState<Option[]>([]);
  const [filters, setFilters] = useState<{
    startDate: DateTime | null;
    endDate: DateTime | null;
    searchText: string;
  }>({
    startDate: null,
    endDate: null,
    searchText: "",
  });
  const [isEditingSubgroup, setIsEditingSubgroup] = useState(false);
  const [isDeletingOption, setIsDeletingOption] = useState(false);
  const [deletingOption, setDeletingOption] = useState<Option | null>(null);

  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const { subgroupGuid } = useParams();

  const { data: subgroup, request: subgroupRequest } =
    useApiRequest<Subgroup | null>(false);

  const { data: optionsData, request: optionRequest } =
    useApiRequest<Option[]>(false);

  const { request: updateSortOrderRequest } = useApiRequest<[]>(false);

  const { data: companySeries, request: companySeriesRequest } =
    useApiRequest<Series[]>(false);

  useEffect(() => {
    setOptions(optionsData ?? []);
  }, [optionsData]);

  usePageMetadata({
    title: subgroup?.subgroupName ?? "",
  });

  const refreshSubgroup = useCallback(() => {
    if (!subgroupGuid) {
      return;
    }
    subgroupRequest(`/company/options/subgroups/${subgroupGuid}`, {
      method: "GET",
    });
  }, [subgroupGuid, subgroupRequest]);

  const refreshOptions = useCallback(() => {
    if (!subgroupGuid) {
      return;
    }
    optionRequest(`company/options/subgroups/${subgroupGuid}/options`, {});
  }, [optionRequest, subgroupGuid]);

  useEffect(() => {
    if (user?.isRetailUser) {
      companySeriesRequest(`retailer/seriesforsubgroup/${subgroupGuid}`, {});
    } else {
      companySeriesRequest(`company/series`, {});
    }
  }, [companySeriesRequest,user?.isRetailUser,subgroupGuid]);

  useEffect(() => {
    if (!subgroupGuid) {
      return;
    }
    refreshOptions();
  }, [refreshOptions, subgroupGuid]);

  useEffect(() => {
    refreshSubgroup();
  }, [refreshSubgroup]);

  const handleNewClick = () => {
    if (!subgroupGuid) {
      return;
    }
    navigate(
      generatePath(routes.manageDataSelectionOptionsAdd.path, {
        subgroupGuid: subgroupGuid,
      })
    );
  };

  useEffect(() => {
    if (!subgroup) {
      return;
    }
    setBreadcrumbs([
      {
        label: routes.manageDataSelectionOptions.label,
        href: routes.manageDataSelectionOptions.path,
      },
      { label: subgroup.subgroupName },
    ]);
  }, [setBreadcrumbs, subgroup]);

  const handleEditClick = useCallback(
    (option: Option) => () => {
      navigate(
        generatePath(routes.manageDataSelectionOptionsEdit.path, {
          subgroupGuid: subgroupGuid!,
          optionGuid: option.optionGuid,
        })
      );
    },
    [navigate, subgroupGuid]
  );

  const handleDeleteOptionSuccess = () => {
    if (deletingOption && optionsData) {
      refreshOptions();
      setDeletingOption(null);
    }
    handleDeleteOptionClose();
  };

  const handleDeleteOptionClose = () => {
    setIsDeletingOption(false);
  };

  const columns: GridColDef<Option>[] = useMemo(
    () => [
      {
        field: "optionName",
        headerName: "Option",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "startDate",
        headerName: "Begin Date",
        flex: 1,
        minWidth: 200,
        valueFormatter: (startDate: number) => formatAsDate(startDate),
      },
      {
        field: "endDate",
        headerName: "End Date",
        flex: 1,
        minWidth: 200,
        valueFormatter: (endDate: number) =>
          !endDate ? "Current" : formatAsDate(endDate),
      },
      {
        field: "series",
        headerName: "Series",
        flex: 1,
        minWidth: 400,
        renderCell: (params) =>
          params.value.map((seriesGuid: Series["seriesGuid"]) => (
            <Chip
              key={seriesGuid}
              label={getSeriesName(companySeries ?? [], seriesGuid)}
              size="small"
              sx={{ marginRight: 1 }}
            />
          )),
      },
      {
        field: "optionPricedPer",
        headerName: "Priced By",
        flex: 1,
        minWidth: 200,
        valueFormatter: (optionPricedPer: OptionPricedPerType) => {
          return getOptionPricePerDisplayName(optionPricedPer);
        },
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        width: 10,
        alighn: "right",
        renderCell: ({ row }) => {
          return (
            user?.isRetailUser ? null :
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit",
                  onClick: handleEditClick(row),
                },
                {
                  label: "Delete",
                  onClick: () => {
                    setDeletingOption(row);
                    setIsDeletingOption(true);
                  },    
                },
              ]}
            />
          );
        },
      },
    ],
    [handleEditClick, companySeries, user?.isRetailUser]
  );
  const filteredOptions = useMemo(
    () => getFilteredOptions(options ?? [], filters),
    [options, filters]
  );

  const handleStartDateSelection = useCallback((startDate: DateTime | null) => {
    setFilters((prevFilters) => ({ ...prevFilters, startDate }));
  }, []);

  const handleEndDateSelection = useCallback((endDate: DateTime | null) => {
    setFilters((prevFilters) => ({ ...prevFilters, endDate }));
  }, []);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchText: event.target.value.toLowerCase(),
      }));
    },
    []
  );

  const handleDragEnd = (sortedOptions: Option[]) => {
    setOptions((prevOptions) => {
      const updatedOptions = updateSortOrder(
        [...sortedOptions, ...prevOptions],
        (a, b) => a.optionGuid === b.optionGuid
      );
      updateSortOrderRequest(`company/options/sortorder/update`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: updatedOptions.map((option, index) => ({
          optionGuid: option.optionGuid,
          sortOrder: index,
        })),
      });
      return updatedOptions;
    });
  };

  return (
    <>
      <PageHeader
        title={subgroup?.subgroupName ?? ""}
        actions={
          !user?.isRetailUser ? 
            <MoreMenuButton
              component={Button}
              componentProps={{ variant: "contained", color: "secondary" }}
              menuItems={[
                {
                  label: "Edit",
                  onClick: () => {
                    setIsEditingSubgroup(true);
                  },
                },
              ]}
            /> : 
            null
        }
      />
      <InfoPanel
        panels={[
          {
            title: "Dates",
            text: `${formatAsDate(subgroup?.startDate)} - ${
              subgroup?.endDate ? formatAsDate(subgroup?.endDate) : "Current"
            }`,
          },
          {
            title: "Required",
            text: subgroup?.subgroupIsRequired ? "Yes" : "No",
          },
          {
            title: "Custom Option",
            text: subgroup?.customOptionAllowed ? "Allowed" : "Not allowed",
          },
          {
            title: "Series",
            html: SeriesList(subgroup?.series ?? []),
          },
        ]}
      />
      <Divider sx={{ marginBottom: 3 }} />

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
        marginBottom={2}
      >
        <Grid item>
          <Stack spacing={2} direction="row">
            <FilterSearchDateRangeButton
              active={false}
              icon={<CalendarTodayOutlinedIcon />}
              onStartDateSelect={handleStartDateSelection}
              onEndDateSelect={handleEndDateSelection}
            >
              Date
            </FilterSearchDateRangeButton>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={2} direction="row">
            <OutlinedInput
              placeholder="Search"
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              value={filters.searchText}
              onChange={handleSearchChange}
            />
            {!user?.isRetailUser && (
              <Button
                onClick={handleNewClick}
                variant="contained"
                color="primary"
              >
                + Option
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
      {optionsData && optionsData.length === 0 && (
        <EmptyState heading="No Options"></EmptyState>
      )}
      {optionsData && optionsData.length > 0 && (
        user?.isRetailUser ?
          <Table
          columns={columns}
          rows={filteredOptions}
          rowSelection={false}
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          getRowId={(row) => row.optionGuid}
          disableVirtualization
          onRowClick={(params) => {
            const { id } = params;
            if (id as string) {
              navigate(
                generatePath(routes.manageDataSelectionOptionsEdit.path, {
                  subgroupGuid: subgroupGuid!,
                  optionGuid: id as string,
                })
              );
            }
          }}
        />
        :
        <TableDraggable
          columns={columns}
          rows={filteredOptions}
          rowSelection={false}
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          getRowId={(row) => row.optionGuid}
          disableVirtualization
          onDragEnd={(sortedOptions) => handleDragEnd(sortedOptions)}
          onRowClick={(params) => {
            const { id } = params;
            if (id as string) {
              navigate(
                generatePath(routes.manageDataSelectionOptionsEdit.path, {
                  subgroupGuid: subgroupGuid!,
                  optionGuid: id as string,
                })
              );
            }
          }}
        />
      )}
      {isEditingSubgroup && subgroup && (
        <NewEditSubGroupModal
          isOpen={isEditingSubgroup}
          subgroup={subgroup}
          isNew={false}
          onSaveSuccessful={() => {
            setIsEditingSubgroup(false);
            refreshSubgroup();
          }}
          onClose={() => {
            setIsEditingSubgroup(false);
          }}
        />
      )}
      {deletingOption && isDeletingOption && (
        <DeleteOptionModal
          option={deletingOption}
          onDeleteSuccess={handleDeleteOptionSuccess}
          onClose={handleDeleteOptionClose}
        />
      )}
    </>
  );
};

const SeriesList = (series: Series[]) => {
  return (
    <>
      {series.map((series: Series) => (
        <Chip
          key={series.seriesGuid}
          label={series.seriesName}
          size="small"
          sx={(theme) => ({
            marginRight: 1,
            backgroundColor: `${theme.palette.grey[200]} !important`,
          })}
        />
      ))}
    </>
  );
};

const getFilteredOptions = (
  options: Option[],
  filters: {
    startDate: DateTime | null;
    endDate: DateTime | null;
    searchText: string;
  }
): Option[] => {
  const isFiltering =
    filters.startDate || filters.endDate || filters.searchText;

  if (!isFiltering) {
    return options;
  }

  let filteredOptions: Option[] = [];

  options.forEach((option) => {
    let matchesFilters = true;

    if (matchesFilters && filters.startDate) {
      const dateFormat = "yyyy/MM/dd";

      const subgroupStartDate = DateTime.fromSeconds(option.startDate).toFormat(
        dateFormat
      );

      const subgroupEndDate = option.endDate
        ? DateTime.fromSeconds(option.endDate).toFormat(dateFormat)
        : null;

      if (filters.endDate === null) {
        matchesFilters =
          subgroupStartDate >= filters.startDate.toFormat(dateFormat);
      } else {
        matchesFilters =
          subgroupStartDate >= filters.startDate.toFormat(dateFormat) &&
          subgroupEndDate != null &&
          subgroupEndDate <= filters.endDate.toFormat(dateFormat);
      }
    }

    if (matchesFilters && filters.searchText) {
      matchesFilters = option.optionName
        .toLowerCase()
        .includes(filters.searchText.toLowerCase());
    }

    if (matchesFilters) {
      filteredOptions.push(option);
    }
  });

  return filteredOptions;
};

export default ManageSelectionOptionsSubgroupRoute;
