import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { DropzoneRef } from "react-dropzone";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import { PageHeader } from "../../components/PageHeader";
import {
  Order,
  OrderStatus,
  OrderQuotePhase,
  getOrderStatusDisplayName,
  getOrderQuotePhaseDisplayName,
  getOrderCard,
  transformOrder,
  canAddChangeOrder,
  canDeleteOrder,
} from "../../types/order";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ArrowLeft, AttachFile, Compress, Expand } from "@mui/icons-material";
import {
  MoreMenuButton,
  MoreMenuButtonProps,
} from "../../components/MoreMenuButton";
import { InfoPanel, InfoPanelProps } from "../../components/InfoPanel";
import { TabPanel, tabA11yProps } from "../../components/TabPanel";
import {
  getUserInitials,
  getUserPhotoUrlSrc,
} from "../../types/user";
import { MenuButton, MenuItemProp } from "../../components/MenuButton";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { OrderCardList } from "./OrderCardList";
import { OrderCard } from "../../types/orderCard";
import { orderCardTypes } from "../../types/orderCardTypes";
import {
  isBusinessBuyerOrderCardVisible,
  OrderCardBusinessBuyer,
} from "../../types/orderCardBusinessBuyer";
import { OrderCardSalesChangeOrder } from "../../types/orderCardSalesChangeOrder";
import { OrderCardEditResponse } from "./orderCardEditResponse";
import { OrderCardNavMenu } from "./OrderCardNavMenu";
import { OrderTabAttachments } from "./OrderTabAttachments";
import { OrderTabSummary } from "./OrderTabSummary";
import { EditTemplateNameModal } from "./EditTemplateNameModal";
import { DocumentModal } from "./DocumentModal";
import { useSession } from "../../hooks/useSession";
import { DeleteOrderModal } from "../../components/DeleteOrderModal";
import { OrderNotes } from "./OrderNotes";
import ConvertProspectModal from "../QuotesRoute/NewQuoteModal";
import produce from "immer";
import { type OrderCardInvoiceDates as OrderCardInvoiceDatesType } from "../../types/orderCardInvoiceDates";
import { DeepPartial } from "../../utils/deepPartial";
import { type OrderCardInvoiceSpecifications as OrderCardInvoiceSpecificationsType } from "../../types/orderCardInvoiceSpecifications";
import { Attachment } from "../../types/attachment";

const tabs = {
  summary: 0,
  attachments: 1,
  comments: 2,
} as const;
type TabValue = (typeof tabs)[keyof typeof tabs];
const tabsAriaLabel = "quote-tabs";

const QuotesAndOrdersDetailRoute: React.FC = () => {
  const { user } = useSession();
  const { tenantGuid, orderGuid } = useParams();
  const { setBreadcrumbs } = useBreadcrumbs(70);
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { state } = useLocation();
  const [tabValue, setTabValue] = useState<TabValue>(tabs.summary);
  const [order, setOrder] = useState<Order | null>(null);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showDocumentDownloadModal, setShowDocumentDownloadModal] =
    useState(false);
  const [boldedOrderCardGuid, setBoldedOrderCardGuid] = useState<string>();
  const [jumpToCardGuid, setJumpToCardGuid] = useState<string | null>(
    state?.jumpToCardGuid ?? null
  );
  const [isEditingTemplateName, setIsEditingTemplateName] = useState(false);
  const [isDeletingOrder, setIsDeletingOrder] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSummaryExpanded, setIsSummaryExpanded] = useState<boolean>(() => {
    const savedState = localStorage.getItem("summaryExpanded");
    return savedState ? JSON.parse(savedState) : true;
  });
  const [isConvertingProspect, setIsConvertingProspect] = useState(false);

  const backTo = useMemo(
    () => new URLSearchParams(window.location.search).get("backTo"),
    []
  );

  const dropzoneRef = useRef<DropzoneRef>(null);
  const navigate = useNavigate();

  const {
    data: orderData,
    request: orderRequest,
    errorMessage: orderErrorMessage,
  } = useApiRequest<Order>();

  const {
    data: duplicateOrderData,
    loading: duplicateOrderLoading,
    request: duplicateOrderRequest,
    errorMessage: duplicateOrderErrorMessage,
  } = useApiRequest<Order>();

  const {
    data: addChangeOrderData,
    request: addChangeOrderRequest,
    loading: addChangeOrderLoading,
    status: addChangeOrderStatus,
    errorMessage: addChangeOrderErrorMessage,
  } = useApiRequest<OrderCardSalesChangeOrder>(false);

  const {
    loading: updateOrderLoading,
    request: updateOrderRequest,
    errorMessage: updateOrderErrorMessage,
  } = useApiRequest<Order>();

  const orderName = order
    ? order.isTemplate
      ? order.templateName ?? ""
      : `ID ${order?.orderIdentifier}`
    : "";

  usePageMetadata({
    title: `${routes.quotesAndOrders.label} ${orderName}`,
  });

  useEffect(() => {
    // Clear any history state that way it does not persist on page refresh
    window.history.replaceState({}, "");
  }, []);

  useEffect(() => {
    if (user?.isBuyerMode) {
      setBreadcrumbs([]);
    } else if (order?.isTemplate) {
      setBreadcrumbs([
        {
          label: routes.manageDataTemplates.label,
          href: routes.manageDataTemplates.path,
        },
        { label: order.templateName ?? "" },
      ]);
    } else if (order?.isTemplate === false) {
      setBreadcrumbs([
        {
          label: routes.quotesAndOrders.label,
          href: tenantGuid
            ? generatePath(routes.quotesAndOrders.path, {
                tenantGuid: tenantGuid,
              })
            : undefined,
        },
        { label: order?.orderIdentifier ? `ID ${order?.orderIdentifier}` : "" },
      ]);
    }
  }, [
    order?.isTemplate,
    order?.orderIdentifier,
    order?.templateName,
    setBreadcrumbs,
    tenantGuid,
    user?.isBuyerMode,
  ]);

  const {
    request: orderAttachmentsRequest,
  } = useApiRequest<Attachment[]>();

  const refreshAttachments = useCallback(() => {
    orderAttachmentsRequest(`/orders/${orderGuid}/attachments`,
      { method: "GET" },
      { onSuccess(data) {
        setOrder((prevOrder) =>
          produce(prevOrder, (draft) => {
            if (!draft) return 
            draft.attachments = data
          })
        );
      }},
    );
  },[orderAttachmentsRequest, orderGuid]);

  const refreshOrder = useCallback(() => {
    if (orderGuid) {
      setIsLoading(true);
      orderRequest(`/orders/${orderGuid}`, { method: "GET" });
    }
  }, [orderGuid, orderRequest]);

  useEffect(() => {
    refreshOrder();
  }, [refreshOrder]);

  useEffect(() => {
    if (orderData) {
      const transformedOrder = transformOrder(orderData);
      setIsLoading(false);
      setOrder(transformedOrder);
    }
  }, [orderData]);

  useEffect(() => {
    if (updateOrderErrorMessage) {
      setIsLoading(false);
      showErrorToast({ message: updateOrderErrorMessage });
    }
  }, [updateOrderErrorMessage, showErrorToast, isLoading]);

  useEffect(() => {
    if (duplicateOrderData?.orderGuid) {
      navigate(
        generatePath(routes.quotesAndOrdersDetail.path, {
          tenantGuid: tenantGuid ?? "",
          orderGuid: duplicateOrderData.orderGuid,
        })
      );
    }
  }, [duplicateOrderData, navigate, tenantGuid]);

  useEffect(() => {
    if (!!duplicateOrderErrorMessage) {
      setIsLoading(false);
      showErrorToast({
        message: duplicateOrderErrorMessage,
      });
    }
  }, [duplicateOrderErrorMessage, showErrorToast, setIsLoading]);

  useEffect(() => {
    if (orderErrorMessage) {
      showErrorToast({ message: orderErrorMessage });
    }
  }, [orderErrorMessage, showErrorToast]);

  const handleAddSalesChangeOrder = useCallback(
    (orderCard: OrderCardSalesChangeOrder) => {
      setOrder((prevOrder) => {
        if (!prevOrder) {
          return prevOrder;
        }

        const orderCards: OrderCard[] = [
          ...(prevOrder.orderCards ?? []),
          orderCard,
        ];

        const updatedOrder: Order = {
          ...prevOrder,
          orderCards,
        };
        const transformedOrder = transformOrder(updatedOrder);
        return transformedOrder;
      });
      setJumpToCardGuid(orderCard.orderCardGuid);
      refreshOrder();
    },
    [refreshOrder]
  );

  useEffect(() => {
    if (
      addChangeOrderData &&
      addChangeOrderStatus === "ok" &&
      addChangeOrderLoading === false
    ) {
      handleAddSalesChangeOrder(addChangeOrderData);
    }
  }, [
    addChangeOrderData,
    addChangeOrderLoading,
    addChangeOrderStatus,
    handleAddSalesChangeOrder,
  ]);

  useEffect(() => {
    if (addChangeOrderErrorMessage) {
      showErrorToast({ message: addChangeOrderErrorMessage });
    }
  }, [addChangeOrderErrorMessage, showErrorToast]);

  const handleNavigateBack = useCallback(() => {
    if (user?.isBuyerMode) {
      return navigate(routes.buyerMode.path);
    } else {
      navigate(`${routes.quotesAndOrders.path}?view=${backTo ?? "kanban"}`);
    }
  },[user?.isBuyerMode, backTo, navigate]);

  const handleSaveQuotePhaseMenuItemClick = useCallback(
    (quotePhase: OrderQuotePhase) => {
      updateOrderRequest(
        `/orders/${orderGuid}/edit`,
        {
          method: "POST",
          data: { quotePhase, orderStatus: order?.orderStatus },
        },
        {
          onSuccess: () => {
            if (!user?.isRetailUser) {
              setOrder((prevOrder) =>
                produce(prevOrder, (draft) => {
                  if (!draft) return 
                  draft.quotePhase = quotePhase;
                })
              );
            }
            handleNavigateBack();
          },
        }
      );
    },
    [updateOrderRequest, orderGuid, order?.orderStatus, setOrder, user?.isRetailUser, handleNavigateBack]
  );

  const handleSaveOrderStatusMenuItemClick = useCallback(
    (orderStatus: OrderStatus) => {
      updateOrderRequest(
        `/orders/${orderGuid}/edit`,
        {
          method: "POST",
          data: { orderStatus: orderStatus },
        },
        {
          onSuccess: () => {
            if (orderStatus !== "q" && order?.orderStatus === "q") {
              // Going from Quote to something else : lots of lock changes and such
            } else {
              setOrder((prevOrder) =>
                produce(prevOrder, (draft) => {
                  if (!draft) return 
                  draft.orderStatus = orderStatus;
                })
              );
            }
            handleNavigateBack();
          },
        }
      );
    },
    [orderGuid, updateOrderRequest, setOrder, order?.orderStatus, handleNavigateBack]
  );

  const handleTabChange = (_: React.SyntheticEvent, newTabValue: TabValue) => {
    setTabValue(newTabValue);
  };

  const handleOrderCardRefreshed = useCallback((orderCardGetResponse: OrderCard) => {
    setOrder((prevOrder) => {
      if (!prevOrder || !prevOrder.orderCards || !prevOrder.logicAffected) return prevOrder;

      prevOrder.orderCards[0] = orderCardGetResponse;

      return prevOrder;
    });
  },[]);

  const handleOrderCardEdited = useCallback(
    (orderCardEditResponse: OrderCardEditResponse<OrderCard>) => {
      if (!orderCardEditResponse || !orderCardEditResponse.orderCard || !orderCardEditResponse.orderCard.orderCardType) return;
      setOrder((prevOrder) =>
        produce(prevOrder, (draft) => {
          if (!draft || !draft.orderCards) return;
          if (orderCardEditResponse.fastOrder.basePlusOptions) draft.basePlusOptions = orderCardEditResponse.fastOrder.basePlusOptions;
          if (orderCardEditResponse.fastOrder.basePrice) draft.basePrice = orderCardEditResponse.fastOrder.basePrice;
          if (orderCardEditResponse.fastOrder.basePricePurchase) draft.basePricePurchase = orderCardEditResponse.fastOrder.basePricePurchase;
          if (orderCardEditResponse.fastOrder.buyerPrice) draft.buyerPrice = orderCardEditResponse.fastOrder.buyerPrice;
          if (orderCardEditResponse.fastOrder.feesTotal) draft.feesTotal = orderCardEditResponse.fastOrder.feesTotal;
          if (orderCardEditResponse.fastOrder.invoicePrice) draft.invoicePrice = orderCardEditResponse.fastOrder.invoicePrice;
          if (orderCardEditResponse.fastOrder.invoiceTotal) draft.invoiceTotal = orderCardEditResponse.fastOrder.invoiceTotal;
          draft.optionsPricePurchase = orderCardEditResponse.optionsPricePurchase ?? 0;
          let index = orderCardEditResponse.orderCard.sortOrder;
          if (draft.orderCards.length <= index || draft.orderCards[index].orderCardGuid !== orderCardEditResponse.orderCard.orderCardGuid) {
            index = draft.orderCards.findIndex((dcard) => dcard.orderCardGuid === orderCardEditResponse.orderCard.orderCardGuid);
          }
          switch (orderCardEditResponse.orderCard.orderCardType) {
            case 'fe':
            case 'so':
              draft.orderCards[index].errors = orderCardEditResponse.orderCard.errors;
              draft.orderCards[index].orderCardStatus = orderCardEditResponse.orderCard.orderCardStatus;
              draft.orderCards[index].isApproved = orderCardEditResponse.orderCard.isApproved;
              break;
            default:
              draft.orderCards[index] = orderCardEditResponse.orderCard;
              break;
          }
          orderCardEditResponse.relatedOrderCards.forEach((card) => {
            let x2 = card.sortOrder;
            if (draft.orderCards!.length <= x2 || draft.orderCards![x2].orderCardGuid !== card.orderCardGuid) {
              x2 = draft.orderCards!.findIndex((dcard) => dcard.orderCardGuid === card.orderCardGuid);
            }
            draft.orderCards![x2] = card;
          });
          Object.entries(orderCardEditResponse.fastOrder).forEach(([key, value]) => {
            (draft as any)[key] = value;
          });
        })
      );
    },[setOrder]
  );

  const handleCurrentCardInViewChange = useCallback((orderCard: OrderCard) => {
    setBoldedOrderCardGuid(orderCard.orderCardGuid);
  }, []);

  const handleCurrentEditingOrderCardChange = useCallback(
    (orderCard: OrderCard | null) => {
      setBoldedOrderCardGuid((prevBoldedOrderCardGuid) => {
        return orderCard ? orderCard.orderCardGuid : prevBoldedOrderCardGuid;
      });
    },
    []
  );

  const handleLinkClicked = useCallback((orderCardGuid: string) => {
    setJumpToCardGuid(orderCardGuid);

    setTimeout(() => {
      setJumpToCardGuid(null);
    }, 100);
  }, []);

  const handleAttachFile = () => {
    if (tabValue === tabs.attachments) {
      dropzoneRef.current?.open();
    } else {
      // We need to open the attachments tab and wait long enough
      // for the tab to render before opening the file selector.
      setTabValue(tabs.attachments);
      setTimeout(() => {
        dropzoneRef.current?.open();
      }, 50);
    }
  };

  const handleDuplicateOrder = useCallback(() => {
    setIsLoading(true);
    duplicateOrderRequest(`/orders/${orderGuid}/duplicate`, { method: "GET" });
  }, [duplicateOrderRequest, orderGuid, setIsLoading]);

  const handleNewSalesChangeOrder = useCallback(() => {
    addChangeOrderRequest(`/orders/${orderGuid}/changeorders/add`, {
      method: "GET",
    });
  }, [addChangeOrderRequest, orderGuid]);

  const handleDeleteOrder = useCallback(() => {
    setIsDeletingOrder(true);
  }, []);

  const handleSaveTemplateClick = () => {
    navigate(routes.manageDataTemplates.path);
  };

  /*
  <Button
  variant="contained"
  color="primary"
  onClick={handleUpdateOrder}
  disabled={saveOrderCardCount > 0}
  hidden={true}
>
  Audit Quote
</Button>
  const handleUpdateOrder = useCallback(
    () => {
      refreshOrder();
      refreshSaveAsOptions();
    },
    [refreshOrder, refreshSaveAsOptions]
  );
  */

  const handleEditTemplateName = () => {
    setIsEditingTemplateName(true);
  };

  const handleEditTemplateNameSuccess = (updatedOrder: Order) => {
    setIsEditingTemplateName(false);
    setOrder((prevOrder) => {
      if (!prevOrder) {
        return prevOrder;
      }
      const transformedOrder = transformOrder({
        ...prevOrder,
        templateName: updatedOrder.templateName,
      });
      return transformedOrder;
    });
  };

  const handleEditTemplateNameClose = () => {
    setIsEditingTemplateName(false);
  };

  const handleDeleteOrderClose = () => {
    setIsDeletingOrder(false);
  };

  const handleDeleteOrderSuccess = () => {
    navigate(
      generatePath(routes.quotesAndOrders.path, {
        tenantGuid: tenantGuid ?? "",
      })
    );
  };

  const handleConvertToQuoteClick = () => {
    setIsConvertingProspect(true);
  };

  const handleSummaryExpandToggle = () => {
    setIsSummaryExpanded((prev) => !prev);
    localStorage.setItem("summaryExpanded", JSON.stringify(!isSummaryExpanded));
  };

  const isProspectValid = (cardData: OrderCardBusinessBuyer["cardData"]) => {
    return (
      cardData.firstName !== "" &&
      cardData.firstName != null &&
      cardData.lastName !== "" &&
      cardData.lastName != null
    );
  };

  const orderCards = useMemo(() => {
    const cards = order?.orderCards?.filter((orderCard) => {
      if (orderCard.isHidden) {
        return false;
      }

      if (
        order.orderStatus !== "p" &&
        orderCard.orderCardType === orderCardTypes.businessBuyer
      ) {
        const businessLogisticsCard = getOrderCard(
          order,
          orderCardTypes.businessLogistics
        );
        return (
          businessLogisticsCard != null &&
          isBusinessBuyerOrderCardVisible(businessLogisticsCard)
        );
      } else if (orderCard.orderCardType === orderCardTypes.subgroupOption) {
        if (orderCard.cardData.customOptionSelected) {
          return true;
        }

        // Check to see if this order card if affected by conditional logic and needs to be hidden.
        const isHidden = (order.logicAffected?.hideSubgroups ?? []).includes(
          orderCard.subgroupGuid + "diabled"
        );
        return !isHidden;
      } else {
        return true;
      }
    });

    return cards ?? [];
  }, [order]);

  const infoPanels: InfoPanelProps["panels"] = useMemo(() => {
    const panels: InfoPanelProps["panels"] = [
      {
        title: "Sales Rep",
        html: (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing="4px"
          >
            {order?.assigneeUser && (
              <>
                <Avatar
                  src={getUserPhotoUrlSrc(order.assigneeUser)}
                  sx={{
                    height: 20,
                    width: 20,
                    fontSize: "10px",
                  }}
                >
                  {getUserInitials(order.assigneeUser)}
                </Avatar>
                <Typography
                  variant="body2"
                  color="text.primary"
                  fontWeight={500}
                >
                  {order.assigneeUserFullName}
                </Typography>
              </>
            )}
          </Stack>
        ),
      },
      {
        title: "Status",
        text: order
          ? order.isTemplate
            ? "Template"
            : getOrderStatusDisplayName(order.orderStatus, user?.isRetailUser)
          : "",
      },
    ];

    if (order && !order.isTemplate && order.orderStatus === "q") {
      panels.push({
        title: "Phase",
        html: order ? (
          <Chip
            label={getOrderQuotePhaseDisplayName(order.quotePhase)}
            color="default"
            size="small"
          />
        ) : undefined,
      });
    }

    if (order && order.isUnconfirmed) {
      panels.push({
        title: "(Unconfirmed)",
        html: undefined,        
      });
    }

    return panels;
  }, [order, user?.isRetailUser]);

  const menuItems = useMemo(() => {
    const items: MoreMenuButtonProps["menuItems"] = [
      {
        label: "Duplicate",
        disabled: duplicateOrderLoading,
        onClick: handleDuplicateOrder,
      },
    ];

    if (order && canAddChangeOrder(order) && !user?.isRetailUser) {
      items.push({
        label: "New Sales Change Order",
        disabled: addChangeOrderLoading,
        onClick: handleNewSalesChangeOrder,
      });
    }

    if (user?.isRetailUser && ["p", "q"].includes(order?.orderStatus ?? "")) {
      items.push({
        label: "Archive",
        onClick: () => handleSaveOrderStatusMenuItemClick("a"),
      });
    }

    if (user && order && canDeleteOrder(order, user)) {
      items.push({
        label: "Delete",
        onClick: handleDeleteOrder,
      });
    }

    return items;
  }, [
    addChangeOrderLoading,
    duplicateOrderLoading,
    handleDeleteOrder,
    handleDuplicateOrder,
    handleNewSalesChangeOrder,
    handleSaveOrderStatusMenuItemClick,
    order,
    user,
  ]);

  const [saveOrderCardCount, setSaveOrderCardCount] = useState<number>(0);

  const [calculatedSaveAsMenuItems, setCalculatedSaveAsMenuItems] = useState<MenuItemProp[]>([]);
  useMemo(() => {
    if (!order || !user || !order.orderCards) return;

    // Quote Phase Menu Items
    const draftMenuItem: MenuItemProp = {
      label: `Quote: ${getOrderQuotePhaseDisplayName("qpd")}`,
      onClick: () => handleSaveQuotePhaseMenuItemClick("qpd"),
    };
    const pendingRetailMenuItem: MenuItemProp = {
      label: `Quote: ${getOrderQuotePhaseDisplayName("qpp")}`,
      onClick: () => handleSaveQuotePhaseMenuItemClick("qpp"),
    };
    const pendingMfgMenuItem: MenuItemProp = {
      label: `Quote: ${
        user?.isRetailUser
          ? "Submit for Review"
          : getOrderQuotePhaseDisplayName("qpn")
      }`,
      onClick: () => handleSaveQuotePhaseMenuItemClick("qpn"),
    };
    const orderReadyMenuItem: MenuItemProp = {
      label: `Quote: ${getOrderQuotePhaseDisplayName("qpo")}`,
      onClick: () => handleSaveQuotePhaseMenuItemClick("qpo"),
    };

    const dividerMenuItem: MenuItemProp = { divider: true };

    // Order Status Menu Items
    const prospectMenuItem: MenuItemProp = {
      label: getOrderStatusDisplayName("p"),
      onClick: () => handleSaveOrderStatusMenuItemClick("p"),
    };
    const quoteMenuItem: MenuItemProp = {
      label: getOrderStatusDisplayName("q"),
      onClick: () => handleSaveOrderStatusMenuItemClick("q"),
    };
    const orderMenuItem: MenuItemProp = {
      label: getOrderStatusDisplayName("o"),
      onClick: () => handleSaveOrderStatusMenuItemClick("o"),
    };
    const scheduledForProductionMenuItem: MenuItemProp = {
      label: getOrderStatusDisplayName("f"),
      onClick: () => handleSaveOrderStatusMenuItemClick("f"),
    };
    const inProductionMenuItem: MenuItemProp = {
      label: getOrderStatusDisplayName("i"),
      onClick: () => handleSaveOrderStatusMenuItemClick("i"),
    };
    const completeMenuItem: MenuItemProp = {
      label: getOrderStatusDisplayName("c"),
      onClick: () => handleSaveOrderStatusMenuItemClick("c"),
    };
    const invoicedMenuItem: MenuItemProp = {
      label: getOrderStatusDisplayName("v"),
      onClick: () => handleSaveOrderStatusMenuItemClick("v"),
    };
    const shippedMenuItem: MenuItemProp = {
      label: getOrderStatusDisplayName("s"),
      onClick: () => handleSaveOrderStatusMenuItemClick("s"),
    };
    const archivedMenuItem: MenuItemProp = {
      label: getOrderStatusDisplayName("a"),
      onClick: () => handleSaveOrderStatusMenuItemClick("a"),
    };
    
    // If this is an unconfirmed order, then use the saveAsOptions as provided
    if (order.isUnconfirmed) {
      if (user.isRetailUser) {
        setCalculatedSaveAsMenuItems([]);
      } else {
        setCalculatedSaveAsMenuItems([
          orderReadyMenuItem,
          dividerMenuItem,
          ...order.saveAsOptions.orderStatuses.map((status) => { 
            return {
              label: getOrderStatusDisplayName(status),
              onClick: () => handleSaveOrderStatusMenuItemClick(status),
            };
          })
        ]);
      }
      return;
    } else 
    /*
      Retail User
      1. Cannot change *anything* if a Quote is Pending Mfg or Order Ready
      2. Cannot change *anything* if an Order is status Order, Scheduled for Production, In Production, Complete, Invoiced or Shipped
      3. Can move a Prospect to Archived if not in Buyer Mode and is at least Admin
      4. Can move a Prospect to Archived if not in Buyer Mode and is at least Admin
      5. Can change a Quote to Quote Phase: Draft or Quote Phase:Pending Mfg (assuming it passes 1.)
      6. Can move an Archived Prospect back to Prospect if not in Buyer Mode and is at least Admin
      7. Can move an Archived Quote back to Quote if not in Buyer mode and is at least Admin
    */
    if (user.isRetailUser) {
      // Retail User
      if (
        (order.orderStatus === "q" && ["qpn","qpo"].includes(order.quotePhase)) ||
        (["o","f","i","c","v","s"].includes(order.orderStatus))
      ) {
        // 1. Cannot change *anything* if a Quote is Pending Mfg or Order Ready
        // 2. Cannot change *anything* if an Order is status Order, Scheduled for Production, In Production, Complete, Invoiced or Shipped
        setCalculatedSaveAsMenuItems([]);
      } else if (order.orderStatus === "p" && !user.isBuyerMode && ["admin","owner"].includes(user.userRole)) {
        // 3. Can move a Prospect to Archived if not in Buyer Mode and is at least Admin
        setCalculatedSaveAsMenuItems([
          archivedMenuItem,
        ]);
      } else if (order.orderStatus === "q" && !user.isBuyerMode && ["admin","owner"].includes(user.userRole)) {
        // 4. Can move a Prospect to Archived if not in Buyer Mode and is at least Admin
        // 5. Can change a Quote to Quote Phase: Draft or Quote Phase:Pending Mfg (assuming it passes 1.)
        setCalculatedSaveAsMenuItems([
          draftMenuItem,
          pendingMfgMenuItem,
          dividerMenuItem,
          archivedMenuItem,
        ]);
      } else if (order.orderStatus === "q") {
        // 5. Can change a Quote to Quote Phase: Draft or Quote Phase:Pending Mfg (assuming it passes 1.)
        setCalculatedSaveAsMenuItems([
          draftMenuItem,
          pendingMfgMenuItem,
        ]);
      } else if (order.orderStatus === "a" && !user.isBuyerMode && ["admin","owner"].includes(user.userRole) && (order.orderCards.length > 0 && order.orderCards[0].orderCardType === "bb")) {
        // 6. Can move an Archived Prospect back to Prospect if not in Buyer Mode and is at least Admin
        setCalculatedSaveAsMenuItems([
          prospectMenuItem,
        ]);
      } else if (order.orderStatus === "a" && !user.isBuyerMode && ["admin","owner"].includes(user.userRole) && !order.confirmedTimestamp) {
        // 7. Can move an Archived Quote back to Quote if not in Buyer mode and is at least Admin
        setCalculatedSaveAsMenuItems([
          quoteMenuItem,
        ]);
      } else {
        setCalculatedSaveAsMenuItems([]); 
      }
      return;
    }

    /* Manufacturer User
      1. Can move an Archived Order back to its prior status if is at least Admin
      2. Can move one forward or backward from Order -- Shipped (except Order can't go backward)
      3. Quotes can change phases to Draft, Pending Retail or Pending Mfg regardless of errors or users
      4. Quotes can change to Phase Order Ready if there are no errors, no incomplete required Order Cards, and no Custom requiring Approval
      5. Quotes that are Order Ready can move to Order if user is at least admin and there are no errors, no incomplete required Order Cards, and no Custom requiring Approval
    */

    if (order.orderStatus === "a") {
      if (!["admin","owner"].includes(user.userRole)) {
        setCalculatedSaveAsMenuItems([]);
      } else {
        // We need the Invoice Dates order card if it exists to figure out what they can go back to.
        const idCardIndex = order.orderCards.findIndex(orderCard => orderCard.orderCardType === "id");
        const idCard = idCardIndex ? (order.orderCards[idCardIndex] as DeepPartial<OrderCardInvoiceDatesType>) : null;
        // 1. Can move an Archived Order back to its prior status if is at least Admin
        if (idCard?.cardData?.shipDateTimestamp) {
          // Has a Ship Date, so it can move back to Order -- Shipped
          setCalculatedSaveAsMenuItems([
            orderMenuItem,
            scheduledForProductionMenuItem,
            inProductionMenuItem,
            completeMenuItem,
            invoicedMenuItem,
            shippedMenuItem,
          ]);
        } else if (idCard?.cardData?.invoiceDateTimestamp) {
          // Has an Invoice Date, so it can move back to Order -- Invoiced
          setCalculatedSaveAsMenuItems([
            orderMenuItem,
            scheduledForProductionMenuItem,
            inProductionMenuItem,
            completeMenuItem,
            invoicedMenuItem,
          ]);
        } else {
          const ipCardIndex = order.orderCards.findIndex(orderCard => orderCard.orderCardType === "ip");
          const ipCard = idCardIndex ? (order.orderCards[ipCardIndex] as DeepPartial<OrderCardInvoiceSpecificationsType>) : null;
          if (ipCard?.cardData?.dateOfMfgTimestamp) {
            // Has a Date of Mfg, so it can move back to Order -- Complete
            setCalculatedSaveAsMenuItems([
              orderMenuItem,
              scheduledForProductionMenuItem,
              inProductionMenuItem,
              completeMenuItem,
            ]);
          } else if (order.inProductionTimestamp) {
            // Has In Production, so it can back to Order -- In Production
            setCalculatedSaveAsMenuItems([
              orderMenuItem,
              scheduledForProductionMenuItem,
              inProductionMenuItem,
            ]);
          } else if (order.scheduledForProductionTimestamp) {
            // Has In Production, so it can back to Order -- Scheduled
            setCalculatedSaveAsMenuItems([
              orderMenuItem,
              scheduledForProductionMenuItem,
            ]);
          } else if (order.confirmedTimestamp) {
            // Has Confirmed, can go back to Order
            setCalculatedSaveAsMenuItems([
              orderMenuItem,
            ]);
          } else {
            // Has no dates, so it's just a quote
            setCalculatedSaveAsMenuItems([
              quoteMenuItem,
            ]);
          }
        }
      }
    } else if (order.orderStatus !== "q") {
      // 2. Can move one forward or backward from Order -- Shipped (except Order can't go backward)
      switch (order.orderStatus) {
        case "o":
          setCalculatedSaveAsMenuItems([scheduledForProductionMenuItem,archivedMenuItem]);
          break;
        case "f": 
          setCalculatedSaveAsMenuItems([orderMenuItem,inProductionMenuItem,archivedMenuItem]);
          break;
        case "i":
          setCalculatedSaveAsMenuItems([scheduledForProductionMenuItem,completeMenuItem,archivedMenuItem]);
          break;
        case "c":
          setCalculatedSaveAsMenuItems([inProductionMenuItem,invoicedMenuItem,archivedMenuItem]);
          break;
        case "v":
          setCalculatedSaveAsMenuItems([completeMenuItem,shippedMenuItem,archivedMenuItem]);
          break;
        case "s":
          setCalculatedSaveAsMenuItems([invoicedMenuItem,archivedMenuItem]);
          break;
        default:
          throw new Error("Invalid Order Status : Unable to determine available Save As menu items");
      }
    } else {
      // 3. Quotes can change phases to Draft, Pending Retail or Pending Mfg regardless of errors or users
      let menuItems: MenuItemProp[] = [];
      if (order.quotePhase !== "qpd") menuItems.push(draftMenuItem);
      if (order.quotePhase !== "qpp") menuItems.push(pendingRetailMenuItem);
      if (order.quotePhase !== "qpn") menuItems.push(pendingMfgMenuItem);
      const hasBadCards = order.orderCards.some((card) => {
        return (card.isRequired && card.orderCardStatus !== "c") ||
        !card.isApproved ||
        card.errors.length > 0;
      });
      if (!hasBadCards) {
        // 4. Quotes can change to Phase Order Ready if there are no errors, no incomplete required Order Cards, and no Custom requiring Approval
        if (order.quotePhase !== "qpo") {
          menuItems.push(orderReadyMenuItem);
        } else if (["admin","owner"].includes(user.userRole)) {
          // 5. Quotes that are Order Ready can move to Order if there are no errors, no incomplete required Order Cards, and no Custom requiring Approval
          menuItems.push(dividerMenuItem);
          menuItems.push(orderMenuItem);
        }
      }
      setCalculatedSaveAsMenuItems(menuItems);
    }
  },[
    setCalculatedSaveAsMenuItems,
    user,
    order,
    handleSaveQuotePhaseMenuItemClick,
    handleSaveOrderStatusMenuItemClick,
  ]);

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 64,
          backgroundColor: "grey.100",
          zIndex: 1000,
        }}
      >
        <PageHeader
          title={
            order?.isTemplate ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing="4px"
              >
                <Typography variant="h5" fontWeight="bold">
                  {order.templateName}
                </Typography>
                {!order.isLocked ? (
                  <IconButton
                    aria-label="edit"
                    color="primary"
                    sx={{ padding: 0 }}
                    onClick={handleEditTemplateName}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                ) : null}
              </Stack>
            ) : (
              order?.orderIdentifier
            )
          }
          actions={
            <Stack spacing={4} direction="row">
              {order?.orderStatus === "p" ? null : (
                <Stack direction="row" spacing={1}>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ padding: 1, minWidth: "initial" }}
                    onClick={() => setExpanded(true)}
                  >
                    <Expand />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ padding: 1, minWidth: "initial" }}
                    onClick={() => setExpanded(false)}
                  >
                    <Compress />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setShowDocumentDownloadModal(true)}
                    sx={{ padding: 1, minWidth: "initial" }}
                  >
                    <InsertDriveFileOutlinedIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      paddingLeft: 1.5,
                      paddingRight: 1.5,
                      minWidth: "initial",
                    }}
                    onClick={handleAttachFile}
                  >
                    <Stack direction={"row"}>
                      <AttachFile
                        aria-label="Attach"
                        sx={{ transform: "rotate(45deg)" }}
                      />
                      {order?.attachments?.length ?? null}
                    </Stack>
                  </Button>
                  <MoreMenuButton
                    component={Button}
                    componentProps={{
                      variant: "contained",
                      color: "secondary",
                    }}
                    menuItems={menuItems}
                  />
                </Stack>
              )}
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNavigateBack}
                >
                  Exit
                </Button>
                {!order ? null : order.isTemplate ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveTemplateClick}
                  >
                    Save Template
                  </Button>
                ) : order.orderStatus === "p" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      updateOrderLoading ||
                      !(
                        orderCards[0]?.cardData &&
                        isProspectValid(
                          orderCards[0]
                            .cardData as OrderCardBusinessBuyer["cardData"]
                        )
                      )
                    }
                    onClick={handleConvertToQuoteClick}
                  >
                    Convert to Quote
                  </Button>
                ) : (
                  <MenuButton
                    disabled={calculatedSaveAsMenuItems.length === 0}
                    menuItems={calculatedSaveAsMenuItems}
                  >
                    {user?.isRetailUser && calculatedSaveAsMenuItems.length === 0 ? "Mfg Edit Only" : "Save As"}
                  </MenuButton>
                )}
              </Stack>
            </Stack>
          }
        />
        <InfoPanel panels={infoPanels} />
        <Divider />
      </Box>
      <Grid container spacing={3} paddingTop={3} paddingBottom={"38%"}>
        {order && order.orderStatus !== "p" && (
          <Grid width={{ xs: "100%", sm: "339px" }}>
            {order && (
              <OrderCardNavMenu
                order={order}
                orderCards={orderCards}
                boldedOrderCardGuid={boldedOrderCardGuid}
                onLinkClicked={handleLinkClicked}
              />
            )}
          </Grid>
        )}

        <Grid xs>
          {order ? (
            <OrderCardList
              order={order}
              orderCards={orderCards}
              onOrderCardEdited={handleOrderCardEdited}
              onOrderCardRefreshed={handleOrderCardRefreshed}
              onCurrentCardInViewChange={handleCurrentCardInViewChange}
              onCurrentEditingOrderCardChange={
                handleCurrentEditingOrderCardChange
              }
              onAddSalesChangeOrder={handleAddSalesChangeOrder}
              expanded={expanded}
              refreshOrder={refreshOrder}
              jumpToCardGuid={jumpToCardGuid}
              isLoading={isLoading}
              saveOrderCardCount={saveOrderCardCount}
              setSaveOrderCardCount={setSaveOrderCardCount}
              setOrder={setOrder}
            />
          ) : null}
        </Grid>

        {order?.orderStatus !== "p" && (
          <Grid width={isSummaryExpanded ? { sm: "378px" } : 0}>
            <Box
              onClick={handleSummaryExpandToggle}
              sx={{
                position: "fixed",
                top: 214,
                marginLeft: "-5px",
                height: "100%",
                width: "5px",
                backgroundColor: "lightgrey",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                zIndex: 99,
              }}
            >
              <Box>
                <ArrowLeft
                  sx={{
                    backgroundColor: "lightgrey",
                    "&:hover": {
                      backgroundColor: "primary.main",
                      color: "white",
                    },
                    borderRadius: "50%",
                    position: "absolute",
                    top: 140,
                    left: -10,
                    transform: isSummaryExpanded ? "rotate(180deg)" : null,
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                position: "sticky",
                top: 220,
                paddingLeft: 2,
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label={tabsAriaLabel}
              >
                <Tab
                  label="Summary"
                  {...tabA11yProps(tabsAriaLabel, tabs.summary)}
                />
                <Tab
                  label="Attachments"
                  {...tabA11yProps(tabsAriaLabel, tabs.attachments)}
                />
                <Tab
                  label="Comments"
                  {...tabA11yProps(tabsAriaLabel, tabs.comments)}
                />
              </Tabs>
              <Box
                sx={{
                  maxHeight: "calc(100vh - 260px)",
                  overflowY: "auto",
                }}
              >
                <TabPanel
                  value={tabValue}
                  index={tabs.summary}
                  tabsAriaLabel={tabsAriaLabel}
                >
                  <OrderTabSummary order={order} />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={tabs.attachments}
                  tabsAriaLabel={tabsAriaLabel}
                >
                  <OrderTabAttachments
                    order={order}
                    dropzoneRef={dropzoneRef}
                    onAttachmentsChanged={refreshAttachments}
                  />
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index={tabs.comments}
                  tabsAriaLabel={tabsAriaLabel}
                >
                  <OrderNotes order={order} />
                </TabPanel>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      {order && isEditingTemplateName ? (
        <EditTemplateNameModal
          order={order}
          isOpen={isEditingTemplateName}
          onSaveSuccessful={handleEditTemplateNameSuccess}
          onClose={handleEditTemplateNameClose}
        />
      ) : null}
      {order && showDocumentDownloadModal && (
        <DocumentModal
          order={order}
          onClose={() => setShowDocumentDownloadModal(false)}
        />
      )}
      {order && isDeletingOrder && (
        <DeleteOrderModal
          order={order}
          onDeleteSuccess={handleDeleteOrderSuccess}
          onClose={handleDeleteOrderClose}
        />
      )}
      {isConvertingProspect && (
        <ConvertProspectModal
          isOpen={true}
          onClose={() => setIsConvertingProspect(false)}
          prospectGuid={order?.orderGuid}
          onSaveSuccessful={(newQuote) => {
            setIsConvertingProspect(false);
            navigate(
              generatePath(routes.quotesAndOrdersDetail.path, {
                tenantGuid: tenantGuid ?? "",
                orderGuid: newQuote?.orderGuid ?? "",
              })
            );
          }}
        />
      )}
    </>
  );
};

export default QuotesAndOrdersDetailRoute;
