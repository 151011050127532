import React, { useId, useMemo } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Contrast from "@mui/icons-material/Contrast";
import { OrderCard } from "../../types/orderCard";
import { CancelOutlined } from "@mui/icons-material";

export type OrderStatusIndicatorMenuItem = {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
};

interface OrderStatusIndicatorProps {
  disabled: boolean;
  orderCard: OrderCard;
  hasErrors: boolean;
  onClearClick?: () => void;
  onDeleteClick?: () => void;
  additionalIndicatorMenuOptions?: OrderStatusIndicatorMenuItem[];
}

export const OrderStatusIndicator: React.FC<OrderStatusIndicatorProps> = ({
  disabled,
  orderCard,
  hasErrors,
  onClearClick,
  onDeleteClick,
  additionalIndicatorMenuOptions,
}) => {
  const id = useId();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const gray = "#D0D5DD";
  const orange = "#F79009";

  const open = Boolean(anchorEl);

  const calculatedIncompleteColor = useMemo(() => {
    const isRequired = orderCard.isRequired;
    const isIncomplete = orderCard.orderCardStatus === "i";
    const isSoWithSelectedOption =
      orderCard.orderCardType === "so" && orderCard.cardData.selectedOptionGuid;
    const isSoWithCustomOption =
      orderCard.orderCardType === "so" &&
      orderCard.cardData.customOptionSelected;
    const isCsOrCo = ["cs","co"].includes(orderCard.orderCardType);

    if (
      !hasErrors &&
      isIncomplete &&
      (isRequired || isSoWithSelectedOption || isSoWithCustomOption || isCsOrCo)
    ) {
      return orange;
    }
    return gray;
  }, [
    orderCard.isRequired,
    hasErrors,
    orderCard.orderCardStatus,
    orderCard.orderCardType,
    orderCard?.cardData,
  ]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (
      disabled ||
      (!onClearClick && !onDeleteClick && !additionalIndicatorMenuOptions)
    ) {
      return;
    }
    setAnchorEl(e.currentTarget);
  };

  const handleAdditionalMenuItemClick = (
    e: React.MouseEvent<HTMLLIElement>,
    onClick?: () => void
  ) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
    handleClose(e);
  };

  const handleClose = (e: {
    preventDefault: Function;
    stopPropagation: Function;
  }) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClearClick = (e: React.MouseEvent<HTMLLIElement>) => {
    if (onClearClick) {
      onClearClick();
    }
    handleClose(e);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLLIElement>) => {
    if (onDeleteClick) {
      onDeleteClick();
    }
    handleClose(e);
  };

  return (
    <div>
      <Button
        id={`order-status-button-${id}`}
        aria-controls={open ? `order-status-menu-${id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        color="secondary"
        endIcon={<ExpandMoreIcon color="action" />}
        disabled={disabled}
        onClick={handleClick}
        sx={{ borderRadius: "100px", paddingLeft: "8px", paddingRight: "8px" }}
      >
        <Contrast
          sx={{
            color: calculatedIncompleteColor,
            fontSize: "18px",
            marginRight: 1,
          }}
        />

        <CheckCircleIcon
          sx={{
            color:
              !hasErrors && orderCard.orderCardStatus === "c"
                ? "#12B76A"
                : gray,
            fontSize: "18px",
            marginRight: 1,
          }}
        />
        <ErrorIcon
          sx={{
            color: hasErrors ? "#E31B54" : gray,
            fontSize: "18px",
          }}
        />
      </Button>
      <Menu
        id={`order-status-menu-${id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `order-status-button-${id}`,
        }}
      >
        {additionalIndicatorMenuOptions?.map((option) => (
          <MenuItem
            key={option.label}
            disabled={option.disabled}
            onClick={(e) => handleAdditionalMenuItemClick(e, option.onClick)}
          >
            <Typography>{option.label}</Typography>
          </MenuItem>
        ))}
        {onClearClick && (
          <MenuItem onClick={handleClearClick}>
            <CancelOutlined fontSize="small" sx={{ color: "#E31B54" }} />
            <Typography color="#E31B54">Clear</Typography>
          </MenuItem>
        )}
        {onDeleteClick && (
          <MenuItem onClick={handleDeleteClick}>
            <CancelOutlined fontSize="small" sx={{ color: "#E31B54" }} />
            <Typography color="#E31B54">Delete</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
