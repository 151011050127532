import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { Stack, Box, Button } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import {
  StateFee,
  getStateFeeTypeDisplayName,
  getStateFeeUomDisplayName,
} from "../../types/stateFee";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Table } from "../../components/Table";
import { NewEditStateFeeModal } from "./NewEditStateFeeModal";
import { Switch } from "../../components/Switch";
import { formatAsCurrency } from "../../utils/number";

const ManageStateFeesRoute: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedStateFee, setSelectedStateFee] = useState<StateFee | null>(
    null
  );
  const { data: stateFees, request: stateFeesRequest } =
    useApiRequest<StateFee[]>(false);

  const {
    request: activationToggleRequest,
    status: activationToggleStatus,
    loading: activateLoading,
  } = useApiRequest();

  usePageMetadata({ title: `Manage ${routes.manageDataStateFees.label}` });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataStateFees.label }]);
  }, [setBreadcrumbs]);

  const refreshStateFees = useCallback(() => {
    stateFeesRequest("/company/statefees", {
      method: "GET",
    });
  }, [stateFeesRequest]);

  useEffect(() => {
    refreshStateFees();
  }, [refreshStateFees]);

  useEffect(() => {
    if (activateLoading === false && activationToggleStatus === "ok") {
      refreshStateFees();
    }
  }, [activationToggleStatus, activateLoading, refreshStateFees]);

  const handleSave = useCallback(() => {
    refreshStateFees();
    setIsEditing(false);
  }, [refreshStateFees]);

  const handleEditClick = (stateFee: StateFee) => {
    setSelectedStateFee(stateFee);
    setIsEditing(true);
  };

  const handleNewStateFeeButtonClick = () => {
    setSelectedStateFee(null);
    setIsEditing(true);
  };

  const handleActiveToggle = useCallback(
    (stateFee: StateFee) => () => {
      activationToggleRequest(
        `/company/statefees/${stateFee.statefeeGuid}/edit`,
        {
          method: "POST",
          data: { isActive: !stateFee.isActive },
        }
      );
    },
    [activationToggleRequest]
  );

  const columns: GridColDef<StateFee>[] = useMemo(() => {
    const cols: GridColDef<StateFee>[] = [
      {
        field: "statefeeState",
        headerName: "State",
        width: 100,
      },
      {
        field: "statefeeType",
        headerName: "Construction Type",
        width: 200,
        valueGetter: (value) => getStateFeeTypeDisplayName(value),
      },
      {
        field: "statefeeAmount",
        headerName: "Fee",
        flex: 1,
        minWidth: 120,
        valueGetter(value, row) {
          const stateFee = formatAsCurrency(value);
          const stateFeeUom = getStateFeeUomDisplayName(row.statefeeUom);
          return `${stateFee}/${stateFeeUom}`;
        },
      },
      {
        field: "isActive",
        headerName: "Active",
        headerAlign: "right",
        align: "right",
        flex: 1,
        minWidth: 100,
        renderCell: (params) => (
          <Box sx={{ paddingTop: 2, paddingLeft: 1 }}>
            <Switch
              sx={{ marginLeft: "auto" }}
              checked={params.row.isActive}
              onChange={handleActiveToggle(params.row)}
            />
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit",
                  onClick: () => handleEditClick(row),
                },
              ]}
            />
          );
        },
      },
    ];

    return cols;
  }, [handleActiveToggle]);

  return (
    <>
      <Stack width="100%" spacing={2}>
        <Box alignSelf="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleNewStateFeeButtonClick}
          >
            + State Fee
          </Button>
        </Box>
        {stateFees && stateFees.length > 0 ? (
          <Box width="100%">
            <Table
              rows={stateFees}
              columns={columns}
              rowSelection={false}
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableColumnResize
              disableRowSelectionOnClick
              getRowId={(row) => row.statefeeGuid}
              onRowClick={(params) => {
                const { id } = params;
                if (id as string) {
                  const row = stateFees.find((sf) => sf.statefeeGuid === (id as string));
                  if (row) {
                    setSelectedStateFee(row);
                    setIsEditing(true);
                  }  
                }
              }}
            />
          </Box>
        ) : null}
      </Stack>
      {isEditing && (
        <NewEditStateFeeModal
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSaveSuccessful={handleSave}
          stateFee={selectedStateFee}
          isNew={!selectedStateFee}
        />
      )}
    </>
  );
};

export default ManageStateFeesRoute;
