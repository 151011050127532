import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Controller, useForm } from "react-hook-form";
import { DateTime } from "luxon";
import { OrderCardBase } from "./OrderCardBase";
import { useOrderCard } from "./OrderCardContext";
import InputField from "../../components/InputField";
import { DatePicker } from "../../components/DatePicker";
import { type OrderCardInvoiceDates as OrderCardInvoiceDatesType } from "../../types/orderCardInvoiceDates";

type FormData = {
  modelYear: number | null;
  shipDateTimestamp: DateTime | null;
  invoiceDateTimestamp: DateTime | null;
};

const getDefaultValues = (
  orderCard: OrderCardInvoiceDatesType | null
): FormData => {
  return {
    modelYear: orderCard?.cardData.modelYear ?? null,
    shipDateTimestamp: orderCard?.cardData.shipDateTimestamp
      ? DateTime.fromSeconds(
          parseInt(orderCard.cardData.shipDateTimestamp.toString())
        )
      : null,
    invoiceDateTimestamp: orderCard?.cardData.invoiceDateTimestamp
      ? DateTime.fromSeconds(
          parseInt(orderCard.cardData.invoiceDateTimestamp.toString())
        )
      : null,
  };
};

interface OrderCardInvoiceDatesProps {
  orderCard: OrderCardInvoiceDatesType;
  expanded: boolean;
  groupIndex: number;
}

export const OrderCardInvoiceDates: React.FC<OrderCardInvoiceDatesProps> = ({
  orderCard,
  expanded,
  groupIndex,
}) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const { control, register, reset, formState, getValues } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const getOrderCardData = (
    formData: FormData
  ): OrderCardInvoiceDatesType["cardData"] => {
    const cardData: OrderCardInvoiceDatesType["cardData"] = {
      ...formData,
      shipDateTimestamp: formData.shipDateTimestamp?.toSeconds() ?? null,
      invoiceDateTimestamp: formData.invoiceDateTimestamp?.toSeconds() ?? null,
    };
    return cardData;
  };

  const handleBlurField = () => {
    const values = getValues();
    const cardData = getOrderCardData(values);
    saveOrderCard(orderCard.orderCardGuid, cardData);
    setEditingOrderCard(null);
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);

    const cardData = getOrderCardData(defaultValues);
    saveOrderCard(orderCard.orderCardGuid, cardData);
  };

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      onClearClick={handleClearClick}
      expanded={expanded}
      groupIndex={groupIndex}
    >
      <Grid container spacing={2} component="form">
        <Grid xs={12} sm={12} md={6}>
          <Controller
            name="invoiceDateTimestamp"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                label="Invoice Date"
                disabled={disabled}
                onFocus={handleEditingField}
                required
                fullWidth
                allowNoDate={false}
                error={!!formState.errors.invoiceDateTimestamp}
                helperText={formState.errors.invoiceDateTimestamp?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            name="shipDateTimestamp"
            control={control}
            render={({ field }) => (
              <DatePicker
                {...field}
                label="Ship Date"
                disabled={disabled}
                onFocus={handleEditingField}
                required
                fullWidth
                allowNoDate={false}
                error={!!formState.errors.shipDateTimestamp}
                helperText={formState.errors.shipDateTimestamp?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("modelYear", {
              onBlur: handleBlurField,
              valueAsNumber: true,
            })}
            label="Model Year"
            onFocus={handleEditingField}
            placeholder="Enter Model Year"
            required
            fullWidth
            disabled={disabled}
            type="number"
            intOnly={true}
          />
        </Grid>
      </Grid>
    </OrderCardBase>
  );
};
