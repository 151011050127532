import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useForm } from "react-hook-form";
import { PageHeader } from "../../components/PageHeader";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { ConstructionOption } from "../../types/constructionOption";
import { FormConstructionData, FormConstructionOptionPricingDefaults } from "./FormConstructionData";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  generatePath,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import { ConstructionSubgroup } from "../../types/constructionSubgroup";
import { ConstructionOptionDetailsFormSection } from "./ConstructionOptionDetailsFormSection";
import { ConstructionOptionPricingFormSection } from "./ConstructionOptionPricingFormSection";

const defaultPricingOptions = FormConstructionOptionPricingDefaults;

const getDefaultValues = (constructionOption: ConstructionOption | null): FormConstructionData => {
  return {
    optionName: constructionOption?.optionName || "",
    optionPricedPer: constructionOption?.optionPricedPer || "",
    optionPricedFor: constructionOption?.optionPricedFor || "a",
    optionPricing:
      constructionOption?.optionPricing.map((pricing) => ({
        ...pricing,
      })) || defaultPricingOptions.slice(0, 1),
  };
};

const ManageConstructionOptionsAddEditRoute: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { constructionSubgroupGuid, constructionOptionGuid } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const isAddRoute = useMemo(
    () => location.pathname.endsWith("/add"),
    [location.pathname]
  );

  const { data: constructionSubgroupData, request: constructionSubgroupRequest } =
    useApiRequest<ConstructionSubgroup>(false);

  const {
    error: constructionOptionRequestError,
    data: constructionOptionData,
    request: constructionOptionRequest,
    loading: constructionOptionLoading,
    status: constructionOptionStatus,
  } = useApiRequest<ConstructionOption>(false);

  const {
    error: constructionOptionCreateUpdateRequestError,
    data: constructionOptionCreateData,
    request: constructionOptionCreateUpdateRequest,
    loading: constructionOptionCreateUpdateLoading,
    status: constructionOptionCreateUpdateStatus,
  } = useApiRequest<ConstructionOption>(false);

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { isDirty, isSubmitted, isValid, errors },
    watch,
  } = useForm<FormConstructionData>({
    defaultValues: getDefaultValues(constructionOptionData),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const optionName = watch("optionName");
  const optionPricedPer = watch("optionPricedPer");
  const optionPricedFor = watch("optionPricedFor");
  usePageMetadata({
    title: constructionOptionGuid ? "Edit Option" : "Add Option",
  });
  
  const refreshConstructionOption = useCallback(() => {
    if (constructionOptionGuid) {
      constructionOptionRequest(`retailer/constoptions/options/${constructionOptionGuid}`, {
        method: "GET",
      });
    }
  }, [constructionOptionGuid, constructionOptionRequest]);

  const [showError, setShowError] = useState(false);
  useEffect(() => {
    if (constructionOptionRequestError || constructionOptionCreateUpdateRequestError) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  },[setShowError,constructionOptionRequestError,constructionOptionCreateUpdateRequestError]);

  // Request list of construction subgroups
  useEffect(() => {
    if (!constructionSubgroupGuid) {
      return;
    }
    constructionSubgroupRequest(`/retailer/constoptions/subgroups/${constructionSubgroupGuid}`, {
      method: "GET",
    });
  }, [constructionSubgroupGuid, constructionSubgroupRequest]);

  // Load option data if editing
  useEffect(() => {
    refreshConstructionOption();
  }, [refreshConstructionOption]);

  // Set breadcrumbs
  useEffect(() => {
    if (!constructionSubgroupData) {
      return;
    }
    setBreadcrumbs([
      {
        label: routes.manageDataConstructionCostsSubgroup.label,
        href: generatePath(routes.manageDataConstructionCostsSubgroup.path, {
          constructionSubgroupGuid: constructionSubgroupData.constructionSubgroupGuid,
        }),
      },
      {
        label: constructionSubgroupData?.subgroupName || "",
        href: generatePath(routes.manageDataConstructionCostsSubgroup.path, {
          constructionSubgroupGuid: constructionSubgroupData.constructionSubgroupGuid,
        }),
      },
      {
        label: constructionOptionData?.optionName ? `Edit Option` : "Add Option",
      },
    ]);
  }, [setBreadcrumbs, constructionOptionData, constructionSubgroupData]);

  // Set default values once we load the construction option data
  useEffect(() => {
    if (constructionOptionData && constructionOptionStatus === "ok" && constructionOptionLoading === false) {
      const defaultValues = getDefaultValues(constructionOptionData);
      reset(defaultValues, { keepDirtyValues: true });
    }
  }, [constructionOptionLoading, constructionOptionStatus, constructionOptionData, reset]);

  // Redirect to the edit route if the construction option was created successfully
  useEffect(() => {
    if (constructionOptionCreateUpdateStatus === "ok" && constructionOptionCreateData && isSubmitted) {
      const defaultValues = getDefaultValues(constructionOptionData);
      reset(defaultValues, { keepDirtyValues: false });

      // If this is a new construction option, redirect to the edit page
      // otherwise redirect to the construction subgroup page
      if (isAddRoute) {
        navigate(
          generatePath(routes.manageDataConstructionOptionsEdit.path, {
            constructionOptionGuid: constructionOptionCreateData.constructionOptionGuid,
          })
        );
      } else {
        navigate(
          generatePath(routes.manageDataConstructionCostsSubgroup.path, {
            constructionSubgroupGuid: constructionOptionCreateData.constructionSubgroupGuid!,
          })
        );
      }
    }
  }, [
    constructionOptionCreateData,
    constructionOptionCreateUpdateStatus,
    navigate,
    constructionSubgroupGuid,
    reset,
    constructionOptionData,
    isAddRoute,
    isSubmitted,
  ]);

  const onSubmit = (data: FormConstructionData) => {
    const optionPricing = data.optionPricing.map((price) => ({
      ...price,
    }));

    if (constructionOptionGuid) {
      constructionOptionCreateUpdateRequest(`retailer/constoptions/options/${constructionOptionGuid}/edit`, {
        method: "POST",
        data: {
          ...data,
          optionPricing: JSON.stringify(optionPricing),
        },
      });
    } else {
      constructionOptionCreateUpdateRequest(
        `/retailer/constoptions/subgroups/${constructionSubgroupGuid}/options/add`,
        {
          method: "POST",
          data: {
            ...data,
            optionPricing: JSON.stringify(optionPricing),
          },
        }
      );
    }
  };

  const handleCancelClick = () => {
    navigate(
      generatePath(routes.manageDataConstructionCostsSubgroup.path, {
        constructionSubgroupGuid: constructionSubgroupGuid ?? constructionOptionData?.constructionSubgroupGuid ?? constructionSubgroupGuid!,
      })
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader
          title={
            <>
              <Typography variant="h5" fontWeight="bold">
                {optionName}
              </Typography>
            </>
          }
          actions={
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid || !isDirty || constructionOptionCreateUpdateLoading}
              >
                {constructionOptionGuid ? "Save" : "Create"}
              </Button>
            </Stack>
          }
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            {showError && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                  Option name must be unique and/or Square Foot pricing Minimum and Maximum footage must not overlap.
              </Alert>
            )}
            <Stack spacing={2} direction="column">
              <Card variant="outlined">
                <CardContent>
                  <ConstructionOptionDetailsFormSection
                    control={control}
                    register={register}
                    errors={errors}
                  />
                </CardContent>
              </Card>
              <Card variant="outlined">
                <CardContent>
                  <ConstructionOptionPricingFormSection
                    control={control}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    optionPricedPer={optionPricedPer}
                    optionPricedFor={optionPricedFor}
                  />
                </CardContent>
              </Card>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
export default ManageConstructionOptionsAddEditRoute;
