import React, { ReactNode, useEffect } from "react";
import { Typography } from "@mui/material";
import { ConfirmModal } from "../../components/ConfirmModal";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { OrderCard } from "../../types/orderCard";

interface ConfirmDeleteModalProps {
  orderCard: OrderCard;
  action: "delete" | "deny";
  heading?: string;
  body?: ReactNode;
  onDelete: (data: any) => void;
  onCancel: () => void;
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  onDelete,
  onCancel,
  action,
  orderCard,
  heading = `${action === "delete" ? "Delete" : "Deny"} this option?`,
  body = (
    <>
      <Typography variant="body2" color="grey.500">
        {`${action === "delete" ? "Deleting" : "Denying"} this custom option
      will remove it from the group, resulting in its exclusion from the
      quote.`}
      </Typography>
      <br />
      <Typography variant="body2" color="grey.500">
        {`${
          action === "delete" ? "Deleting" : "Denying"
        } this custom option cannot be undone.`}
      </Typography>
    </>
  ),
}) => {
  const { showErrorToast } = useGlobalToastNotificationContext();
  const { loading, request, errorMessage } = useApiRequest();

  const handleDelete = () => {
    request(`/ordercards/${orderCard.orderCardGuid}/delete`, {
      method: "DELETE",
    }, {
      onSuccess(data) {
        onDelete(data);
      },
    });
  };

  useEffect(() => {
    if (errorMessage) {
      showErrorToast({ message: errorMessage });
    }
  }, [errorMessage, showErrorToast]);

  return (
    <ConfirmModal
      isOpen={true}
      heading={heading}
      isDestructive
      confirmDisabled={loading}
      body={body}
      confirmButtonText={action === "delete" ? "Delete" : "Deny"}
      onConfirm={handleDelete}
      onClose={onCancel}
    />
  );
};
