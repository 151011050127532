import { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { OrderCardBusinessLogistics as OrderCardBusinessLogisticsType } from "../../types/orderCardBusinessLogistics";
import { OrderCardBusinessBuyer as OrderCardBusinessBuyerType } from "../../types/orderCardBusinessBuyer";
import { OrderCardBase } from "./OrderCardBase";
import InputField from "../../components/InputField";
import { Controller, useForm } from "react-hook-form";
import SelectField from "../../components/SelectField";
import { stateSelectOptions } from "../../types/states";
import { useOrderCard } from "./OrderCardContext";
import { Order } from "../../types/order";
import { useApiRequest } from "../../hooks/useApiRequest";
import { OrderCardEditResponse } from "./orderCardEditResponse";
import { OrderCard } from "../../types/orderCard";

type FormData = {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  lotNumber: string;
  postalCode: string;
  city: string;
  state: string;
  county: string;
  homePhone: string;
  mobilePhone: string;
  email: string;
};

const getDefaultValues = (
  orderCard: OrderCardBusinessBuyerType | null
): FormData => {
  return {
    firstName: orderCard?.cardData.firstName ?? "",
    lastName: orderCard?.cardData.lastName ?? "",
    address1: orderCard?.cardData.address1 ?? "",
    address2: orderCard?.cardData.address2 ?? "",
    lotNumber: orderCard?.cardData.lotNumber ?? "",
    postalCode: orderCard?.cardData.postalCode ?? "",
    city: orderCard?.cardData.city ?? "",
    state: orderCard?.cardData.state ?? "",
    county: orderCard?.cardData.county ?? "",
    homePhone: orderCard?.cardData.homePhone ?? "",
    mobilePhone: orderCard?.cardData.mobilePhone ?? "",
    email: orderCard?.cardData.email ?? "",
  };
};

interface OrderCardBusinessBuyerProps {
  order: Order;
  orderCard: OrderCardBusinessBuyerType;
  orderCardBusinessLogistics: OrderCardBusinessLogisticsType;
  expanded: boolean;
  groupIndex: number;
  onOrderCardEdited: (
    orderCardEditResponse: OrderCardEditResponse<OrderCard>
  ) => void;
}

export const OrderCardBusinessBuyer: React.FC<OrderCardBusinessBuyerProps> = ({
  order,
  orderCard,
  orderCardBusinessLogistics,
  expanded,
  groupIndex,
  onOrderCardEdited,
}) => {
  const { saveOrderCard, setEditingOrderCard } = useOrderCard();

  const disabled = orderCard.isLocked;

  const { control, register, reset, formState, getValues } = useForm<FormData>({
    defaultValues: getDefaultValues(orderCard),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const {
    request: saveOrderCardRequest,
  } = useApiRequest<OrderCardEditResponse<OrderCardBusinessBuyerType>>(false, "orderCard");

  const handleBlurField = () => {
    saveOrderCardRequest(`/ordercards/${orderCard.orderCardGuid}/edit`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: { 'cardData': getValues() },
    }, {
      onSuccess: (data) => {
        onOrderCardEdited(data);
      },
      onUnsuccess() {
        //
      } 
    });
  };

  const handleEditingField = () => {
    setEditingOrderCard(orderCard);
  };

  const handleClearClick = () => {
    const defaultValues = getDefaultValues(null);
    reset(defaultValues);
    saveOrderCard(orderCard.orderCardGuid, defaultValues);
  };

  useEffect(() => {
    reset(getDefaultValues(orderCard), { keepDirtyValues: true });
  }, [orderCard, reset]);

  return (
    <OrderCardBase
      disabled={disabled}
      orderCard={orderCard}
      onClearClick={handleClearClick}
      expanded={expanded}
      groupIndex={groupIndex}
    >
      <Grid container spacing={2} component="form">
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("firstName", { onBlur: handleBlurField })}
            label="First Name"
            placeholder="Enter First Name"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            required={order.orderStatus === "p"}
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.firstName}
            helperText={formState.errors.firstName?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("lastName", { onBlur: handleBlurField })}
            label="Last Name"
            placeholder="Enter Last Name"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            required={
              order.orderStatus === "p" ||
              orderCardBusinessLogistics.cardData.soldAs === "r"
            }
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.lastName}
            helperText={formState.errors.lastName?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("address1", { onBlur: handleBlurField })}
            label="Address (Build Site)"
            placeholder="Enter Address (Build Site)"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            required={
              order.orderStatus !== "p" &&
              orderCardBusinessLogistics.cardData.shipTo === "b"
            }
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.address1}
            helperText={formState.errors.address1?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("address2", { onBlur: handleBlurField })}
            label="Address 2 (Build Site)"
            placeholder="Enter Address 2 (Build Site)"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            required={
              order.orderStatus !== "p" &&
              orderCardBusinessLogistics.cardData.shipTo === "b"
            }
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.address2}
            helperText={formState.errors.address2?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("lotNumber", { onBlur: handleBlurField })}
            label="Lot Number (Build Site)"
            placeholder="Enter Lot Number (Build Site)"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            required={
              order.orderStatus !== "p" &&
              orderCardBusinessLogistics.cardData.shipTo === "b"
            }
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.lotNumber}
            helperText={formState.errors.lotNumber?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("postalCode", { onBlur: handleBlurField })}
            label="Postal Code (Build Site)"
            placeholder="Enter Postal Code (Build Site)"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            required={
              order.orderStatus !== "p" &&
              orderCardBusinessLogistics.cardData.shipTo === "b"
            }
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.postalCode}
            helperText={formState.errors.postalCode?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("city", { onBlur: handleBlurField })}
            label="City (Build Site)"
            placeholder="Enter City (Build Site)"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            required={
              order.orderStatus !== "p" &&
              orderCardBusinessLogistics.cardData.shipTo === "b"
            }
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.city}
            helperText={formState.errors.city?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Controller
            control={control}
            name="state"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                label="State (Build Site)"
                placeholder="Enter State (Build Site)"
                required={
                  order.orderStatus !== "p" &&
                  orderCardBusinessLogistics.cardData.shipTo === "b"
                }
                onFocus={handleEditingField}
                fullWidth
                disabled={disabled}
                options={stateSelectOptions}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onBlur={() => {
                  handleBlurField();
                  field.onBlur();
                }}
              />
            )}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("county", { onBlur: handleBlurField })}
            label="County (Build Site)"
            placeholder="Enter County (Build Site)"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.county}
            helperText={formState.errors.county?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("homePhone", { onBlur: handleBlurField })}
            label="Home Phone"
            placeholder="Enter Home Phone"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.homePhone}
            helperText={formState.errors.homePhone?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("mobilePhone", { onBlur: handleBlurField })}
            label="Mobile Phone"
            placeholder="Enter Mobile Phone"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.mobilePhone}
            helperText={formState.errors.mobilePhone?.message}
          />
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <InputField
            {...register("email", { onBlur: handleBlurField })}
            label="Email"
            placeholder="Enter Email"
            onFocus={handleEditingField}
            fullWidth
            disabled={disabled}
            sx={{ marginBottom: 2 }}
            error={!!formState.errors.email}
            helperText={formState.errors.email?.message}
          />
        </Grid>
      </Grid>
    </OrderCardBase>
  );
};
