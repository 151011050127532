import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Stack, Avatar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Switch } from "../../components/Switch";
import { NewEditUserModal } from "./NewEditUserModal";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { User } from "../../types/user";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useSession } from "../../hooks/useSession";
import { Table } from "../../components/Table";
import { UserRole, getUserRoleDisplayName } from "../../types/userRole";
import { useGlobalToastNotificationContext } from "../../context/GlobalToastNotificationContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { formatAsPhoneNumber } from "../../utils/number";

function ManageUsersRoute() {
  const [isEditing, setIsEditing] = useState(false);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { user, isCurrentUser } = useSession();
  const { showSuccessToast, showErrorToast } =
    useGlobalToastNotificationContext();
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  usePageMetadata({ title: `Manage ${routes.manageDataUsers.label}` });

  const { data: users, request: usersRequest } = useApiRequest<User[]>(false);

  const refreshUsers = useCallback(() => {
    usersRequest("/users", {
      method: "GET",
    });
  }, [usersRequest]);

  const {
    request: sendInviteRequest,
    status: sendInviteStatus,
    loading: sendInviteLoading,
    error: sendInviteError,
  } = useApiRequest();

  const {
    request: activationToggleRequest,
    status: activationToggleStatus,
    loading: activateLoading,
  } = useApiRequest();

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataUsers.label }]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    if (activateLoading === false && activationToggleStatus === "ok")
      refreshUsers();
  }, [activationToggleStatus, refreshUsers, activateLoading]);

  useEffect(() => {
    if (sendInviteLoading === false && sendInviteStatus === "ok") {
      showSuccessToast({ message: "Invite Sent!" });
    } else if (sendInviteError != null) {
      showErrorToast({ message: "Something went wrong. Please try again.." });
    }
  }, [
    sendInviteStatus,
    refreshUsers,
    sendInviteLoading,
    sendInviteError,
    showSuccessToast,
    showErrorToast,
  ]);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers]);

  const handleActiveToggle = useCallback(
    (user: User) => () => {
      activationToggleRequest(`/users/${user.userGuid}/activate`, {
        method: "POST",
        data: {
          isActive: !user.isActive,
        },
      });
    },
    [activationToggleRequest]
  );

  const handleSendInviteClick = useCallback(
    (user: User) => () => {
      if (user) {
        sendInviteRequest("/users/invite", {
          method: "POST",
          data: { userGuid: user.userGuid },
        });
      }
    },
    [sendInviteRequest]
  );

  const handleEditClick = useCallback(
    (user: User) => () => {
      setSelectedUser(user);
      setIsEditing(true);
    },
    []
  );

  const handleNewUserButtonClick = () => {
    setSelectedUser(null);
    setIsEditing(true);
  };

  const handleClose = () => {
    setIsEditing(false);
  };

  const handleSave = useCallback(() => {
    const message = selectedUser != null ? "User saved." : "User created.";
    showSuccessToast({ message: message });
    refreshUsers();
    setIsEditing(false);
  }, [refreshUsers, selectedUser, showSuccessToast]);

  const columns: GridColDef<User>[] = useMemo(() => {
    const cols: GridColDef<User>[] = [
      {
        field: "userFirstName",
        headerName: "Name",
        minWidth: 300,
        maxWidth: 500,
        flex: 0.5,
        renderCell({ row, value }) {
          const userInitials = `${row.userFirstName.charAt(
            0
          )} ${row.userLastName.charAt(0)}`;

          const userProfileImageSrc = row.userPhotoUrl
            ? `${process.env.REACT_APP_API_BASE_URL}/${row.userPhotoUrl}`
            : undefined;

          return (
            <Stack direction="row" alignItems="center" columnGap={1}>
              <Avatar alt={userInitials} src={userProfileImageSrc}>
                {userInitials}
              </Avatar>
              <Box component="span" fontWeight={500}>
                {value}
              </Box>
            </Stack>
          );
        },
        valueGetter: (_value, row) => {
          return `${row.userFirstName} ${row.userLastName}`;
        },
      },
      {
        field: "userEmail",
        headerName: "Email",
        minWidth: 350,
        maxWidth: 600,
        flex: 0.6,
      },
      {
        field: "userPhoneNumber",
        headerName: "Phone",
        width: 150,
        renderCell: ({ row }) => formatAsPhoneNumber(row.userPhoneNumber),
      },
      {
        field: "userRole",
        headerName: "Permission",
        width: 122,
        valueGetter: (value) => {
          if (!value) {
            return value;
          }
          return getUserRoleDisplayName(value);
        },
      },
      {
        field: "isActive",
        headerName: "Active",
        headerAlign: "right",
        align: "right",
        flex: 0.2,
        width: 65,
        renderCell: (params) => (
          <Box sx={{ paddingTop: 2, paddingLeft: 1 }}>
            <Switch
              sx={{ marginLeft: "auto" }}
              disabled={
                user?.userRole !== UserRole.OWNER || isCurrentUser(params.row)
              }
              checked={params.row.isActive}
              onChange={handleActiveToggle(params.row)}
            />
          </Box>
        ),
      },
    ];

    if (user?.userRole === UserRole.OWNER) {
      cols.push({
        field: "actions",
        headerName: "",
        width: 10,
        sortable: false,
        renderCell: ({ row }) => {
          const menuItems = [];

          if (!isCurrentUser(row)) {
            menuItems.push({
              label: "Edit",
              onClick: handleEditClick(row),
            });
          }

          menuItems.push({
            label: "Send Invite",
            disabled: !row.isActive,
            onClick: handleSendInviteClick(row),
          });

          return <MoreMenuButton menuItems={menuItems} />;
        },
      });
    }

    return cols;
  }, [
    handleActiveToggle,
    handleEditClick,
    handleSendInviteClick,
    isCurrentUser,
    user?.userRole,
  ]);

  return (
    <>
      <Stack width="100%" spacing={2}>
        {user?.userRole === UserRole.OWNER ? (
          <Box alignSelf="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewUserButtonClick}
            >
              + User
            </Button>
          </Box>
        ) : null}
        {users ? (
          <Box width="100%">
            <Table
              rows={users}
              columns={columns}
              rowSelection={false}
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableColumnResize
              disableRowSelectionOnClick
              getRowId={(row) => row.userGuid}
              onRowClick={(params) => {
                const { id } = params;
                if (id as string) {
                  const row = users.find((u) => u.userGuid === (id as string));
                  if (row) {
                    setSelectedUser(row);
                    setIsEditing(true);
                  }  
                }
              }}  
            />
          </Box>
        ) : null}
      </Stack>
      {isEditing && (
        <NewEditUserModal
          isOpen={isEditing}
          isNew={!selectedUser}
          user={selectedUser}
          onSaveSuccessful={handleSave}
          onClose={handleClose}
        />
      )}
    </>
  );
}

export default ManageUsersRoute;
