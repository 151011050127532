import React, { useEffect } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { Modal } from "../../components/Modal";
import { getLogicThenRequireHideAsDisplayName } from "../../types/logic";
import { Option } from "../../types/option";
import { Category, findOption } from "../../types/category";
import { useApiRequest } from "../../hooks/useApiRequest";
import { LogicTextSelectionOption } from "../../components/LogicTextSelectionOption";
import { LogicTextSelectionOptionAttribute } from "../../components/LogicTextSelectionOptionAttribute";
import { LogicThisTargetStatement } from "../../components/LogicThisTargetStatement";

type OptionLogicsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  option: Option;
};

export const OptionLogicsModal: React.FC<OptionLogicsModalProps> = ({
  isOpen,
  option,
  onClose,
}) => {
  const { data: categoriesData, request: categoriesRequest } =
    useApiRequest<Category[]>(false);

  useEffect(() => {
    categoriesRequest(`/company/options/${option.optionGuid}/logiccategories`, { method: "GET" });
  }, [categoriesRequest,option.optionGuid]);

  

  return (
    <Modal
      heading={`${option.logics.length} Conditional Logic Statements`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box sx={{ width: { xs: "100%", sm: "550px", md: "686px" } }}>
        <Stack spacing={3}>
          <Typography variant="caption" color="grey.500">
            The {option.optionName} selection option appears in the following
            conditional logic statements. Any change made to this option will
            also affect these statements.
          </Typography>
          <Box border="1px solid" borderColor="grey.300" borderRadius="4px">
            <Stack
              divider={<Divider flexItem sx={{ marginInline: 2 }} />}
              sx={{
                fontWeight: 500,
                maxHeight: "calc(100vh - 225px)",
                overflowY: "auto",
              }}
            >
              {option.logics.map((logic) => {
                const ifStatement = (
                  <>
                    {logic.ifType === "a" ? (
                      <LogicTextSelectionOptionAttribute
                        option={findOption(
                          categoriesData ?? [],
                          logic.ifOptionGuid
                        )}
                        logicAttributeselectionGuid={
                          logic.ifAttributeselectionGuid ?? ""
                        }
                      />
                    ) : logic.ifType === "o" ? (
                      <LogicTextSelectionOption
                        optionName={logic.ifOptionName ?? ""}
                      />
                    ) : null}{" "}
                    is selected
                  </>
                );

                const thenStatement = getLogicThenRequireHideAsDisplayName(
                  logic.thenRequireHide
                ).toLowerCase();

                const thisStatements = logic.thisTargets.map(
                  (thisTarget, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index !== 0 ? (
                          <Typography color="grey.500" marginX={1}>
                            &
                          </Typography>
                        ) : null}
                        <LogicThisTargetStatement
                          categories={categoriesData ?? []}
                          thisTarget={thisTarget}
                        />
                      </React.Fragment>
                    );
                  }
                );

                return (
                  <Stack
                    key={logic.logicGuid}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                    padding={2}
                  >
                    <Avatar
                      sx={{
                        color: "grey.600",
                        backgroundColor: "grey.100",
                        width: 46,
                        height: 46,
                        fontSize: "14px",
                        letterSpacing: "normal",
                      }}
                    >
                      <CallSplitIcon />
                    </Avatar>
                    <Stack spacing={1} flexGrow="1" width="100%">
                      <Stack
                        direction="row"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <span style={{ marginRight: "8px" }}>If</span>
                        {ifStatement}
                        <span style={{ marginRight: "4px" }}>, then</span>
                        {thenStatement}
                        <span style={{ marginRight: "8px" }}> </span>
                        {thisStatements}
                      </Stack>
                      {logic.logicError && (
                        <Alert severity="error" icon={false}>
                          {logic.logicError.longDesc}
                        </Alert>
                      )}
                    </Stack>
                  </Stack>
                );
              })}
            </Stack>
          </Box>
          <Stack direction="row" justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={onClose}
            >
              Okay
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
