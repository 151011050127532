import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import { Freight } from "../../types/freight";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { PlaceOutlined, Search } from "@mui/icons-material";
import { NewEditModal } from "./NewEditModal";
import { EmptyState } from "../../components/EmptyState";
import { Table } from "../../components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { formatAsCurrency } from "../../utils/number";
import { TenantFreight } from "../../types/tenantFreight";
import { TenantFreightDefaults } from "./TenantFreightDefaults";
import { FilterSearchButton } from "../../components/FilterSearchButton";
import { stateSelectOptions } from "../../types/states";

const ManageFreightRoute: React.FC = () => {
  const [editingFreight, setEditingFreight] = useState<Freight | null>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedState, setSelectedState] = useState<string | null>(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  const {
    data: freight,
    loading,
    request: freightRequest,
  } = useApiRequest<Freight[]>(false);
  const [tenantFreight, setTenantFreight] = useState<TenantFreight | undefined>(
    undefined
  );
  const { data: tenantFreightData, request: tenantFreightRequest } =
    useApiRequest<TenantFreight>(false);

  usePageMetadata({ title: `Manage ${routes.manageDataFreight.label}` });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataFreight.label }]);
  }, [setBreadcrumbs]);

  const refreshFreight = useCallback(() => {
    freightRequest("/company/freights", {
      method: "GET",
    });
  }, [freightRequest]);

  const handleNewButtonClick = () => {
    setEditingFreight(null);
    setIsEditing(true);
  };

  const filteredFreight = useMemo(() => {
    if (!freight) {
      return [];
    }
    return freight.filter((freight) => {
      const filters =
        freight.freightCity.toLowerCase().includes(searchText) ||
        freight.freightState.toLowerCase().includes(searchText) ||
        freight.freightMileage.toString().includes(searchText) ||
        formatAsCurrency(parseFloat(freight.freightPrice14))
          .toString()
          .includes(searchText) ||
        formatAsCurrency(parseFloat(freight.freightPrice16))
          .toString()
          .includes(searchText);

      return selectedState
        ? freight.freightState === selectedState && filters
        : filters;
    });
  }, [freight, searchText, selectedState]);

  const handleSave = useCallback(() => {
    refreshFreight();
    setIsEditing(false);
  }, [refreshFreight]);

  useEffect(() => {
    refreshFreight();
  }, [refreshFreight]);

  const calculate14Total = useMemo(
    () => (freight: Freight) => {
      if (
        !tenantFreight?.tenantFreightCost14 ||
        !tenantFreight.tenantMileageCostCap ||
        !tenantFreight.tenantCostPerMile
      ) {
        return "--";
      }
      return formatAsCurrency(
        tenantFreight?.tenantFreightCost14 +
          parseFloat(freight.freightPrice14) +
          Math.min(
            tenantFreight.tenantMileageCostCap,
            tenantFreight.tenantCostPerMile * parseFloat(freight.freightMileage)
          )
      );
    },
    [
      tenantFreight?.tenantFreightCost14,
      tenantFreight?.tenantMileageCostCap,
      tenantFreight?.tenantCostPerMile,
    ]
  );

  const calculate16Total = useMemo(
    () => (freight: Freight) => {
      if (
        !tenantFreight?.tenantFreightCost16 ||
        !tenantFreight.tenantMileageCostCap ||
        !tenantFreight.tenantCostPerMile
      ) {
        return "--";
      }
      return formatAsCurrency(
        tenantFreight?.tenantFreightCost16 +
          parseFloat(freight.freightPrice16) +
          Math.min(
            tenantFreight.tenantMileageCostCap,
            tenantFreight.tenantCostPerMile * parseFloat(freight.freightMileage)
          )
      );
    },
    [
      tenantFreight?.tenantFreightCost16,
      tenantFreight?.tenantMileageCostCap,
      tenantFreight?.tenantCostPerMile,
    ]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value.toLowerCase());
    },
    []
  );

  const handleStateSelection = useCallback((state: string) => {
    setSelectedState(state);
  }, []);

  const columns: GridColDef<Freight>[] = useMemo(
    () => [
      {
        field: "freightCity",
        headerName: "City",
        flex: 0.65,
        minWidth: 155,
      },
      {
        field: "freightState",
        headerName: "State",
        flex: 0.3,
        minWidth: 85,
        headerAlign: "right",
        align: "right",
      },
      {
        field: "freightPrice14",
        headerName: "Base Rate/14' Floor",
        flex: 0.5,
        minWidth: 125,
        headerAlign: "right",
        align: "right",
        valueFormatter: (value: number) => formatAsCurrency(value),
        type: "string",
      },
      {
        field: "freightPrice16",
        headerName: "Base Rate/16' Floor",
        flex: 0.5,
        minWidth: 125,
        headerAlign: "right",
        align: "right",
        valueFormatter: (value: number) => formatAsCurrency(value),
        type: "string",
      },
      {
        flex: 0.5,
        minWidth: 125,
        field: "freightMileage",
        valueFormatter: (value: number) => `${value} Miles`,
        headerName: "Route Mileage",
        headerAlign: "right",
        align: "right",
      },
      {
        minWidth: 125,
        field: "14Total",
        headerName: "Calc. Rate/14' Floor",
        flex: 0.5,
        headerAlign: "right",
        align: "right",
        renderCell: ({ row }) => calculate14Total(row),
      },
      {
        minWidth: 125,
        field: "i",
        headerName: "Calc. Rate/16' Floor",
        flex: 0.5,
        headerAlign: "right",
        align: "right",
        renderCell: ({ row }) => calculate16Total(row),
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        align: "right",
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit Freight Rate",
                  onClick: () => {
                    setEditingFreight(row);
                    setIsEditing(true);
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [calculate14Total, calculate16Total]
  );

  useEffect(() => {
    if (tenantFreightData) {
      setTenantFreight(tenantFreightData);
    }
  }, [tenantFreightData]);

  useEffect(() => {
    tenantFreightRequest("/company/freightbase", { method: "GET" });
  }, [tenantFreightRequest]);

  const handleTenantFreightChange = useCallback(
    (updatedTenantFreight: TenantFreight) => {
      setTenantFreight(updatedTenantFreight);
    },
    []
  );

  return (
    <>
      <Box>
        {tenantFreight ? (
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{ marginBottom: 5 }}
          >
            <TenantFreightDefaults
              tenantFreight={tenantFreight}
              onTenantFreightChange={handleTenantFreightChange}
            />
          </Stack>
        ) : null}
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
          marginBottom={2}
        >
          <Grid item>
            <FilterSearchButton
              active={false}
              icon={<PlaceOutlined />}
              items={stateSelectOptions}
              onItemSelect={handleStateSelection}
            >
              State
            </FilterSearchButton>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction={"row-reverse"}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNewButtonClick}
                >
                  + Freight Rate
                </Button>
              </Box>
              <OutlinedInput
                placeholder="Search"
                onChange={handleSearchChange}
                size="small"
                color="primary"
                sx={{ backgroundColor: "white", height: 37 }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </Stack>
          </Grid>
        </Grid>
        {freight && freight.length === 0 && !loading && (
          <EmptyState heading="No Freight Rates" />
        )}
        {freight && freight.length > 0 && (
          <Table
            columns={columns}
            rows={filteredFreight}
            rowSelection={false}
            disableColumnFilter
            disableColumnMenu
            disableColumnResize
            disableRowSelectionOnClick
            getRowId={(row) => row.freightGuid}
            onRowClick={(params) => {
              const { id } = params;
              if (id as string) {
                const row = filteredFreight.find((f) => f.freightGuid === (id as string));
                if (row) {
                  setEditingFreight(row);
                  setIsEditing(true);
                }  
              }
            }}  
          />
        )}
      </Box>
      {isEditing && (
        <NewEditModal
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSaveSuccessful={handleSave}
          freight={editingFreight}
          isNew={!editingFreight}
        />
      )}
    </>
  );
};

export default ManageFreightRoute;
