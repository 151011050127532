import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { type OrderCardConstructionHiddenOption as OrderCardConstructionHiddenOptionType } from "../../types/orderCardConstructionHiddenOption";
import { OrderCardBase } from "./OrderCardBase";
import { Order } from "../../types/order";
import { formatAsCurrency } from "../../utils/number";
import { Chip } from "@mui/material";

interface OrderCardConstructionHiddenOptionProps {
  order: Order;
  orderCard: OrderCardConstructionHiddenOptionType;
  expanded: boolean;
  groupIndex: number;
}

export const OrderCardConstructionHiddenOption: React.FC<OrderCardConstructionHiddenOptionProps> = ({
  order,
  orderCard,
  expanded,
  groupIndex,
}) => {

  const disabled = order.orderStatus !== "q";

  return (
    <>
      <OrderCardBase
        disabled={disabled}
        orderCard={orderCard}
        expanded={expanded}
        groupIndex={groupIndex}
        subtitle={formatAsCurrency(orderCard?.totalPrice ?? 0)}
        isRequired={false}
        chipLabel={order?.retailer?.includeReqHidConstCostsInHomePrice ? "(*included in home price)" : undefined}
      >
      <Grid container>
        {(orderCard?.cardData?.costNames ?? []).map((item, index) => {
          return (
            <Grid xs={12} lineHeight={2.5} key={index}>
              <Chip
                label={item}
                variant="outlined"
                style={{backgroundColor: "white", borderColor: "#ddd"}}
                sx={{
                  justifyContent: "flex-start",
                  width: '100%',
                  padding: '8px 0',
                  borderRadius: '8px',
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      </OrderCardBase>
    </>
  );
};
