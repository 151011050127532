import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { PageHeader } from "../../components/PageHeader";
import { EmptyState } from "../../components/EmptyState";
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  ConstructionOption,
  ConstructionOptionPricedPerType,
  getConstructionOptionPricePerDisplayName,
} from "../../types/constructionOption";
import { TableDraggable } from "../../components/TableDraggable";
import { GridColDef } from "@mui/x-data-grid";
import { 
  ConstructionSubgroup,
  getConstructionGroupTypeDisplayName,
} from "../../types/constructionSubgroup";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { InfoPanel } from "../../components/InfoPanel";
import Search from "@mui/icons-material/Search";
import { DateTime } from "luxon";
import { updateSortOrder } from "../../utils/array";
import { NewEditConstructionSubgroupModal } from "../ManageConstructionCostsRoute/NewEditConstructionSubgroupModal";
import { DeleteConstructionOptionModal } from "../../components/DeleteConstructionOptionModal";

const ManageConstructionCostsSubgroupRoute: React.FC = () => {
  const [constructionOptions, setConstructionOptions] = useState<ConstructionOption[]>([]);
  const [filters, setFilters] = useState<{
    startDate: DateTime | null;
    endDate: DateTime | null;
    searchText: string;
  }>({
    startDate: null,
    endDate: null,
    searchText: "",
  });
  const [isEditingConstructionSubgroup, setIsEditingConstructionSubgroup] = useState(false);
  const [isDeletingConstructionOption, setIsDeletingConstructionOption] = useState(false);
  const [deletingConstructionOption, setDeletingConstructionOption] = useState<ConstructionOption | null>(null);

  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();
  const { constructionSubgroupGuid } = useParams();

  const { data: constructionSubgroup, request: constructionSubgroupRequest } =
    useApiRequest<ConstructionSubgroup | null>(false);

  const { data: constructionOptionsData, request: constructionOptionRequest } =
    useApiRequest<ConstructionOption[]>(false);

  const { request: updateSortOrderRequest } = useApiRequest<[]>(false);

  useEffect(() => {
    setConstructionOptions(constructionOptionsData ?? []);
  }, [constructionOptionsData]);

  usePageMetadata({
    title: constructionSubgroup?.subgroupName ?? "",
  });

  const refreshConstructionSubgroup = useCallback(() => {
    if (!constructionSubgroupGuid) {
      return;
    }
    constructionSubgroupRequest(`/retailer/constoptions/subgroups/${constructionSubgroupGuid}`, {
      method: "GET",
    });
  }, [constructionSubgroupGuid, constructionSubgroupRequest]);

  const refreshOptions = useCallback(() => {
    if (!constructionSubgroupGuid) {
      return;
    }
    constructionOptionRequest(`retailer/constoptions/subgroups/${constructionSubgroupGuid}/options`, {});
  }, [constructionOptionRequest, constructionSubgroupGuid]);

  useEffect(() => {
    if (!constructionSubgroupGuid) {
      return;
    }
    refreshOptions();
  }, [refreshOptions, constructionSubgroupGuid]);

  useEffect(() => {
    refreshConstructionSubgroup();
  }, [refreshConstructionSubgroup]);

  const handleNewClick = () => {
    if (!constructionSubgroupGuid) {
      return;
    }
    navigate(
      generatePath(routes.manageDataConstructionOptionsAdd.path, {
        constructionSubgroupGuid: constructionSubgroupGuid,
      })
    );
  };

  useEffect(() => {
    if (!constructionSubgroup) {
      return;
    }
    setBreadcrumbs([
      {
        label: routes.manageDataConstructionCostsSubgroup.label,
        href: routes.manageDataConstructionCostsSubgroup.path,
      },
      { label: constructionSubgroup.subgroupName },
    ]);
  }, [setBreadcrumbs, constructionSubgroup]);

  const handleEditClick = useCallback(
    (constructionOption: ConstructionOption) => () => {
      navigate(
        generatePath(routes.manageDataConstructionOptionsEdit.path, {
          constructionOptionGuid: constructionOption.constructionOptionGuid,
        })
      );
    },
    [navigate]
  );

  const handleDeleteConstructionOptionSuccess = () => {
    if (deletingConstructionOption && constructionOptionsData) {
      refreshOptions();
      setDeletingConstructionOption(null);
    }
    handleDeleteConstructionOptionClose();
  };

  const handleDeleteConstructionOptionClose = () => {
    setIsDeletingConstructionOption(false);
  };

  const columns: GridColDef<ConstructionOption>[] = useMemo(
    () => [
      {
        field: "optionName",
        headerName: "Option",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "optionPricedPer",
        headerName: "Priced By",
        flex: 1,
        minWidth: 200,
        valueFormatter: (constructionOptionPricedPer: ConstructionOptionPricedPerType) => {
          return getConstructionOptionPricePerDisplayName(constructionOptionPricedPer);
        },
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        width: 10,
        alighn: "right",
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit",
                  onClick: handleEditClick(row),
                },
                {
                  label: "Delete",
                  onClick: () => {
                    setDeletingConstructionOption(row);
                    setIsDeletingConstructionOption(true);
                  },    
                },
              ]}
            />
          );
        },
      },
    ],
    [handleEditClick]
  );
  const filteredOptions = useMemo(
    () => getFilteredOptions(constructionOptions ?? [], filters),
    [constructionOptions, filters]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchText: event.target.value.toLowerCase(),
      }));
    },
    []
  );

  const handleDragEnd = (sortedOptions: ConstructionOption[]) => {
    setConstructionOptions((prevOptions) => {
      const updatedConstructionOptions = updateSortOrder(
        [...sortedOptions, ...prevOptions],
        (a, b) => a.constructionOptionGuid === b.constructionOptionGuid
      );
      updateSortOrderRequest(`retailer/constoptions/options/sortorder/update`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: updatedConstructionOptions.map((constructionOption, index) => ({
          constructionOptionGuid: constructionOption.constructionOptionGuid,
          sortOrder: index,
        })),
      });
      return updatedConstructionOptions;
    });
  };

  return (
    <>
      <PageHeader
        title={constructionSubgroup?.subgroupName ?? ""}
        actions={
          <MoreMenuButton
            component={Button}
            componentProps={{ variant: "contained", color: "secondary" }}
            menuItems={[
              {
                label: "Edit",
                onClick: () => {
                  setIsEditingConstructionSubgroup(true);
                },
              },
            ]}
          />
        }
      />
      <InfoPanel
        panels={[
          {
            title: "Type",
            text: constructionSubgroup ? getConstructionGroupTypeDisplayName(constructionSubgroup.subgroupType) : "",
          },
          {
            title: "Active",
            text: (constructionSubgroup?.isActive ?? false) ? "Yes" : "No",
          },
        ]}
      />
      <Divider sx={{ marginBottom: 3 }} />

      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={"space-between"}
        marginBottom={2}
      >
        <Grid item></Grid>
        <Grid item>
          <Stack spacing={2} direction="row">
            <OutlinedInput
              placeholder="Search"
              size="small"
              color="primary"
              sx={{ backgroundColor: "white", height: 37 }}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              value={filters.searchText}
              onChange={handleSearchChange}
            />
            <Button
              onClick={handleNewClick}
              variant="contained"
              color="primary"
            >
              + Option
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {constructionOptionsData && constructionOptionsData.length === 0 && (
        <EmptyState heading="No Construction Options"></EmptyState>
      )}
      {constructionOptionsData && constructionOptionsData.length > 0 && (
        <TableDraggable
          columns={columns}
          rows={filteredOptions}
          rowSelection={false}
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          getRowId={(row) => row.constructionOptionGuid}
          disableVirtualization
          onDragEnd={(sortedOptions) => handleDragEnd(sortedOptions)}
          onRowClick={(params) => {
            const { id } = params;
            if (id as string) {
              navigate(
                generatePath(routes.manageDataConstructionOptionsEdit.path, {
                  constructionOptionGuid: id as string,
                })
              );
            }
          }}
        />
      )}
      {isEditingConstructionSubgroup && constructionSubgroup && (
        <NewEditConstructionSubgroupModal
          isOpen={isEditingConstructionSubgroup}
          constructionSubgroup={constructionSubgroup}
          isNew={false}
          onSaveSuccessful={() => {
            setIsEditingConstructionSubgroup(false);
            refreshConstructionSubgroup();
          }}
          onClose={() => {
            setIsEditingConstructionSubgroup(false);
          }}
        />
      )}
      {deletingConstructionOption && isDeletingConstructionOption && (
        <DeleteConstructionOptionModal
          constructionOption={deletingConstructionOption}
          onDeleteSuccess={handleDeleteConstructionOptionSuccess}
          onClose={handleDeleteConstructionOptionClose}
        />
      )}
    </>
  );
};

const getFilteredOptions = (
  constructionOptions: ConstructionOption[],
  filters: {
    searchText: string;
  }
): ConstructionOption[] => {
  const isFiltering =
    filters.searchText;

  if (!isFiltering) {
    return constructionOptions;
  }

  let filteredOptions: ConstructionOption[] = [];

  constructionOptions.forEach((constructionOption) => {
    let matchesFilters = true;

    if (filters.searchText) {
      matchesFilters = constructionOption.optionName
        .toLowerCase()
        .includes(filters.searchText.toLowerCase());
    }

    if (matchesFilters) {
      filteredOptions.push(constructionOption);
    }
  });

  return filteredOptions;
};

export default ManageConstructionCostsSubgroupRoute;
