import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { usePageMetadata } from "../../hooks/usePageMetadata";
import { routes } from "../../routes";
import { useApiRequest } from "../../hooks/useApiRequest";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { FilterList, Search } from "@mui/icons-material";
import { NewEditModal } from "./NewEditModal";
import { EmptyState } from "../../components/EmptyState";
import { Table } from "../../components/Table";
import { GridColDef } from "@mui/x-data-grid";
import { MoreMenuButton } from "../../components/MoreMenuButton";
import { Vendor } from "../../types/vendor";
import { FilterSearchButton } from "../../components/FilterSearchButton";
import { Switch } from "../../components/Switch";

const ManageVendorsRoute: React.FC = () => {
  const [editingVendor, setEditingVendor] = useState<Vendor | null>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedVendorType, setSelectedVendorType] = useState<string | null>(
    null
  );
  const { setBreadcrumbs } = useBreadcrumbs();
  const {
    data: vendors,
    loading,
    request: vendorRequest,
  } = useApiRequest<Vendor[]>(false);

  const {
    request: activationToggleRequest,
    status: activationToggleStatus,
    loading: activateLoading,
  } = useApiRequest();

  usePageMetadata({ title: `Manage ${routes.manageDataVendors.label}` });

  useEffect(() => {
    setBreadcrumbs([{ label: routes.manageDataVendors.label }]);
  }, [setBreadcrumbs]);

  const refreshVendors = useCallback(() => {
    vendorRequest("/company/vendors", {
      method: "GET",
    });
  }, [vendorRequest]);

  // Update Vendor list when we toggle an active state
  useEffect(() => {
    if (activateLoading === false && activationToggleStatus === "ok")
      refreshVendors();
  }, [activationToggleStatus, refreshVendors, activateLoading]);

  const handleNewButtonClick = () => {
    setEditingVendor(null);
    setIsEditing(true);
  };

  const handleActiveToggle = useCallback(
    (vendor: Vendor) => () => {
      activationToggleRequest(`/company/vendors/${vendor.vendorGuid}/edit`, {
        method: "POST",
        data: {
          isActive: !vendor.isActive,
        },
      });
    },
    [activationToggleRequest]
  );

  const filteredVendors = useMemo(() => {
    if (!vendors) {
      return [];
    }
    return vendors.filter((vendor) => {
      const filters =
        vendor.vendorName.toLowerCase().includes(searchText) ||
        vendor.vendorType.toLowerCase().includes(searchText) ||
        vendor.vendorIdentifier.toLowerCase().includes(searchText);

      return selectedVendorType
        ? vendor.vendorType === selectedVendorType && filters
        : filters;
    });
  }, [vendors, searchText, selectedVendorType]);

  const handleSave = useCallback(() => {
    refreshVendors();
    setIsEditing(false);
  }, [refreshVendors]);

  useEffect(() => {
    refreshVendors();
  }, [refreshVendors]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value.toLowerCase());
    },
    []
  );

  const handleTypeSelection = useCallback((vendor: string) => {
    setSelectedVendorType(vendor);
  }, []);

  const vendorTypeSelectOptions = useMemo(() => {
    if (!vendors) {
      return [];
    }
    const types = vendors.map((vendor) => vendor.vendorType);
    return Array.from(new Set(types)).map((type) => ({
      value: type,
      label: type,
    }));
  }, [vendors]);

  const columns: GridColDef<Vendor>[] = useMemo(
    () => [
      {
        field: "vendorName",
        headerName: "Vendor Name",
        cellClassName: "font-500",
        flex: 1,
        minWidth: 200,
      },
      {
        field: "vendorType",
        headerName: "Vendor Type",
        flex: 1,
        minWidth: 209,
      },
      {
        field: "vendorIdentifier",
        headerName: "Vendor ID",
        flex: 1,
        minWidth: 100,
        valueFormatter: (value) => `ID ${value}`,
      },
      {
        field: "isActive",
        flex: 0,
        headerName: "Active",
        renderCell: (params) => (
          <Box sx={{ paddingTop: 2, paddingLeft: 1 }}>
            <Switch
              checked={params.row.isActive}
              onChange={handleActiveToggle(params.row)}
            />
          </Box>
        ),
      },
      {
        field: "actions",
        headerName: "",
        width: 10,
        align: "right",
        sortable: false,
        renderCell: ({ row }) => {
          return (
            <MoreMenuButton
              menuItems={[
                {
                  label: "Edit",
                  onClick: () => {
                    setEditingVendor(row);
                    setIsEditing(true);
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [handleActiveToggle]
  );

  return (
    <>
      <Box>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent={"space-between"}
          marginBottom={2}
        >
          <Grid item>
            <FilterSearchButton
              icon={<FilterList />}
              disabled={!vendors || vendors.length === 0}
              items={vendorTypeSelectOptions}
              onItemSelect={handleTypeSelection}
            >
              Vendor Type
            </FilterSearchButton>
          </Grid>
          <Grid item>
            <Stack spacing={2} direction={"row-reverse"}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNewButtonClick}
                >
                  + Vendor
                </Button>
              </Box>
              <OutlinedInput
                placeholder="Search"
                disabled={!vendors || vendors.length === 0}
                onChange={handleSearchChange}
                size="small"
                color="primary"
                sx={{ backgroundColor: "white", height: 37 }}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </Stack>
          </Grid>
        </Grid>
        {vendors && vendors.length === 0 && !loading && (
          <EmptyState heading="No Vendors Yet" />
        )}
        {vendors && vendors.length > 0 && (
          <Table
            columns={columns}
            rows={filteredVendors}
            rowSelection={false}
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnResize
            disableRowSelectionOnClick
            getRowId={(row) => row.vendorGuid}
            onRowClick={(params) => {
              const { id } = params;
              if (id as string) {
                const row = filteredVendors.find((v) => v.vendorGuid === (id as string));
                if (row) {
                  setEditingVendor(row);
                  setIsEditing(true);
                }  
              }
            }}
          />
        )}
      </Box>
      {isEditing && (
        <NewEditModal
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          onSaveSuccessful={handleSave}
          vendor={editingVendor}
          isNew={!editingVendor}
        />
      )}
    </>
  );
};

export default ManageVendorsRoute;
